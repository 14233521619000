import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { red } from "@mui/material/colors";
import CardActions from "@mui/material/CardActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { makeApiCall } from "../../common/makeApiCall";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Button from "@mui/material/Button";
import { formatDate } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Divider from '@mui/material/Divider';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Chip,
  Stack,
  Hidden,
} from "@mui/material";
import Toast from "../../common/toastMessage";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import * as yup from "yup";
import FlagIcon from '@mui/icons-material/Flag';
import Reportpost from "./Reportpost";

export const Readpost = () => {
  // Toast Start

  const [openToast, setOpenToast] = useState(false);
  //    console.log("openToast", openToast);
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const closeToast = () => {
    setOpenToast(false);
  };

  // Toast End
  const [isUpvoted, setIsUpvoted] = useState(false);
  const location = useLocation();
  // console.log("location", location)
  const postId = location.pathname.split("/")[2];
  // console.log("postID :", postId)
  // const { data } = location.state;
  // console.log("datalocation", location.state)

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [upvoteCount, setUpvoteCount] = useState();
  const [viewCount, setViewCount] = useState();
  const [commentText, setCommentText] = useState("");
  const MAX_CHARACTERS = 100; // Adjust as needed
  const [initialValues, setInitialValues] = useState({
    message: "",
    postId: postId,
  });
  // console.log("initialValues", initialValues?.message)
  const validationSchema = yup.object().shape({
    message: yup
      .string()
      .max(100, "Message must be at most 2500 characters")
  });
  const [openReport, setOpenReport] = useState();
  const [flagId, setFlagId] = useState();
  console.log("flagId", flagId);
  const handleClickOpen = (flagData) => {
    console.log("flagData", flagData);
    setFlagId(flagData);
    setOpenReport(true);
  };
  const handleClickClose = () => {
    setOpenReport(false);
  };
  // ===========================| UPDATE VIEW COUNT API CALL |==========================>
  useEffect(() => {
    const updateViewCount = async () => {
      // console.log("updateViewCount", updateViewCount)
      try {
        const response = await makeApiCall("post", "/post/detail", {
          id: postId,
        });
        // console.log("API RES DATA :", response)
        if (response.status === 200) {
          setData(response.data.data);
          setViewCount(response.data.data.viewCount); // Update state with new view count
          setUpvoteCount(response.data.data.updateCount);
        }
      } catch (error) {
        // console.error('Error updating view count:', error);
      }
    };
    updateViewCount();
  }, [postId, setUpvoteCount]); // Run effect only when data.id changes

  // ==============================| UPVOTE API CALL |==============================>
  const handleUpvoteClick = async ({ }) => {
    try {
      const response = await makeApiCall("post", "/post/upvotecount", {
        id: postId,
      });
      if (response.status == 200) {
        setUpvoteCount(
          upvoteCount && upvoteCount != null && upvoteCount != undefined
            ? parseInt(upvoteCount) + 1
            : parseInt(data.upvoteCount) + 1
        ); // Update state here
        // alert("Like added success")
        setIsUpvoted(true);
      }
    } catch (error) {
      // console.error('Error upvoting post:', error);
      // Handle errors gracefully (e.g., display an error message)
    }
  };
  // =========================| COMMENT ADD API CALL |===================================>
  const handleCommentSubmit = async (values, { setSubmitting, resetForm }) => {
    // console.log("handleCommentSubmit", handleCommentSubmit);
    setSubmitting(true);
    // Check for user login token in localStorage
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      // Redirect to login page if not logged in
      navigate("/login");
      return; // Prevent further execution if not logged in
    }
    try {
      // Make the API call with user's token in headers (assuming authorization mechanism)
      const response = await makeApiCall("post", "/comment/add", values, {
        headers: {
          Authorization: `Bearer ${userToken}`, // Adjust based on your authorization method
        },
      });
      // console.log('successful:', response);
      // console.log('ADD successful:', response.status);
      if (response.status === 200) {
        // navigate("/");
        navigate("/", {
          state: {
            open: true,
            message: response?.data?.message,
            type: "success",
          },
        });
        // console.log("successfulresponse:", response?.data?.message);
        // return 0;
        // resetForm(); // Optional, reset the form after successful submission
      } else if (response.status === 400) {
        // console.log(response);
        setOpenToast(true);
        setToastMessage(response?.data?.errorMessage?.msg);
        setToastType("error");
      } else {
        throw new Error("Comment failed. Please try again later.");
      }
    } catch (error) {
      console.error("Comment error:", error);
      // alert(error.message); // Display user-friendly error message
    } finally {
      setSubmitting(false);
    }
  };
  // ============================| SHARE FUNCTION |=========================>
  const handleShare = (postId) => {
    // console.log("postIdpostIdpostIdpostId", postId);
    const baseUrl = "https://theopenletter.org"; // Replace 'https://example.com' with your server URL
    if (navigator.share) {
      // Check if Web Share API is supported
      navigator
        .share({
          url: `${baseUrl}/viewpost/${postId}`, // Construct the complete URL
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      // console.log('Web Share API not supported');
    }
  };

  const avatarColors = ['#0866ff', '#0F9D58', '#E60023', "#F4B400", '#FF00BF',];// Array of different background colors
  return (
    <>
      <Grid container sx={{ gap: 3, padding: 1 }}>
        {data && data != "" && data != undefined && (
          <Grid item xs={12}>
            <Card sx={{ textAlign: "left" }}>
              <CardHeader
                sx={{
                  ".no-ellipsis-card-header": {
                    overflow: "visible", // Ensures content isn't truncated
                    whiteSpace: "nowrap", // Prevents content from wrapping
                  },
                }}
                avatar={
                  <Avatar
                    // sx={{ bgcolor: red[500] }}
                    sx={{ backgroundColor: "#404040" }}
                  >
                    {data.authorName.charAt(0).toUpperCase()}
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="settings"
                    disableRipple
                    sx={{
                      "&:hover": {
                        backgroundColor: "inherit", // Ensure background remains consistent
                        cursor: "default", // Optional: Set cursor to default for non-clickable appearance
                      },
                    }}
                  >
                    <Hidden mdDown>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={`Organization : ${data.organization}`}
                          color="primary"
                        />
                        <Chip
                          label={`Category : ${data.category}`}
                          color="primary"
                        />
                        <Chip
                          label={`Act Date : ${data.actionDate}`}
                          color="primary"
                        />
                      </Stack>
                    </Hidden>
                  </IconButton>
                }
                title={data.authorName}
                subheader={
                  <>
                    {" "}
                    {/* Wrap subheaders to avoid overwriting */}
                    <Typography variant="body2">{data.createdAt}</Typography>
                    <Typography variant="body2">
                      {data?.district ? data?.district : "NA"}
                    </Typography>
                  </>
                }
              />
              <Hidden smUp>
                <Stack
                  sx={{
                    display: "flex",
                    padding: 2,
                    flexDirection: "column",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                  spacing={1}
                >
                  <Chip
                    size="small"
                    label={`Organization : ${data.organization}`}
                    color="primary"
                  />
                  <Chip
                    size="small"
                    label={`Category : ${data.category}`}
                    color="primary"
                  />
                  <Chip
                    size="small"
                    label={`Act Date : ${data.actionDate}`}
                    color="primary"
                  />
                </Stack>
              </Hidden>
              <CardContent sx={{ textAlign: "left" }}>
                <Typography color="primary" gutterBottom variant="h6">
                  {data.title}
                </Typography>
                <Typography variant="body2" color="body2">
                  {parse(data.message)}
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{ justifyContent: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid
                    item
                    onClick={() => {
                      const userToken = localStorage.getItem("token");
                      if (!userToken) {
                        navigate("/login"); // Redirect to login if no token
                        return;
                      }
                      if (!isUpvoted) {
                        handleUpvoteClick(data);
                      }
                      // handleUpvoteClick(data);
                      // Call upvote handler if token is valid
                    }}
                  >
                    <IconButton aria-label="like">
                      {/* <ThumbUpOffAltIcon color='primary' /> */}
                      {isUpvoted ? (
                        <ThumbUpRoundedIcon color="primary" />
                      ) : (
                        <ThumbUpOffAltIcon />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="text.secondary">
                      {upvoteCount ? upvoteCount : data.upvoteCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton aria-label="views">
                      <VisibilityIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="text.secondary">
                      {data.viewCount}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    {/* Report Button */}
                    <Grid container sx={{
                      display: {
                        xs: 'none',
                        sm: 'block',
                        md: 'block',
                        lg: 'block',
                        xl: 'block',
                      },
                      justifyContent: "end",
                      alignItems: "center"
                    }}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        startIcon={<FlagIcon />}
                        onClick={() => handleClickOpen(data.id)}
                      >
                        Report
                      </Button>
                    </Grid>
                    {/* IconButton */}
                    <Box sx={{
                      display: {
                        xs: 'block',
                        sm: 'none',
                        md: 'none',
                        lg: 'none',
                        xl: 'none',
                      },
                    }}>
                      <IconButton onClick={() => handleClickOpen(data.id)}>
                        <FlagIcon fontSize="small" color="error" />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  alignItems="center"
                  sx={{
                    justifyContent: "end",
                    ml: 1
                    // display: { xs: 'none', sm: 'flex' }
                  }}
                >
                  <Grid
                    item
                    onClick={() => handleShare(data.id)}
                  >
                    <IconButton aria-label="share">
                      <ShareIcon color="primary" />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            {/* ===========================|COMMENT ADD|===================> */}
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}> {/* Grid container with spacing and horizontal centering */}
              <Grid item xs={12} sx={{ marginTop: 3 }}>


                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleCommentSubmit}
                >
                  {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                      <Grid item xs={12} sx={{ marginTop: 3, }}>
                        <TextField
                          sx={{
                            '& .MuiFilledInput-root': {
                              backgroundColor: 'white', // Set background color to white
                              alignItems: 'flex-end', // Align input's content to the bottom
                              paddingBottom: '16px', // Add padding to the bottom to accommodate the icon
                              '&.Mui-focused': {
                                backgroundColor: 'white', // Change background color to red when focused
                              },
                            },
                            '& .MuiInputAdornment-root': {
                              alignSelf: 'flex-end', // Ensure the adornment aligns to the bottom
                              marginBottom: '8px', // Adjust as needed to align with the bottom
                            },
                            '& .MuiFilledInput-input': {
                              paddingBottom: '8px', // Ensure there's enough padding at the bottom for text
                            }
                          }}
                          id="input-with-icon-textfield"
                          label="Write a comment"
                          fullWidth
                          variant="filled"
                          name='message'
                          inputProps={{ maxLength: MAX_CHARACTERS }} // Set maximum length
                          helperText={`${formik.values.message.length}/${MAX_CHARACTERS} characters used`}
                          error={formik.values.message.length > MAX_CHARACTERS}
                          value={formik.values.message.trimStart()} // Trim leading and trailing spaces
                          onChange={(event) => {

                            const trimmedValue = event.target.value.trimStart(); // Trim leading and trailing spaces
                            formik.setFieldValue('message', trimmedValue); // Update Formik's field value
                          }}
                          // type="submit"
                          {...(formik.values.message.length > 0 && {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end" sx={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
                                  <IconButton type="submit">
                                    <SendIcon color="primary" />
                                  </IconButton>

                                </InputAdornment>
                              )
                            }
                          })}
                        // maxRows={4}
                        />
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>

              {/* ===========================|COMMENT LIST|===================> */}
              <Grid item xs={12} sx={{ marginTop: 3 }}>
                <Box>
                  <List sx={{ mb: 2 }}>
                    {" "}
                    {/* Add spacing below the list */}
                    {data.Comments.map((comment, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              // backgroundColor: "#ff0000", // Set your desired background color here
                              backgroundColor:
                                avatarColors[index % avatarColors.length], // Set background color from the array
                            }}
                          >
                            {" "}
                            {comment.userName
                              ? comment.userName.charAt(0).toUpperCase()
                              : ""}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          fullWidth
                          sx={{
                            // backgroundColor: "#b7e3fc",
                            backgroundColor: '#80808024',
                            borderRadius: 1,
                            padding: 1,
                          }}
                          primary={
                            <Typography variant="caption">
                              {comment.userName}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body1"
                              sx={{ display: "flex", flexWrap: "wrap" }}
                            >
                              {comment.message}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>

          </Grid>

        )}
      </Grid>
      <>
        <>
          {openToast && (
            <Toast
              open={openToast}
              message={toastMessage}
              type={toastType}
              onClose={closeToast}
            />
          )}
        </>
        {
          openReport && <Reportpost setOpenReport={setOpenReport} flagId={flagId} />
        }
      </>
    </>
  );
};
