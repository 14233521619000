export const Reportlist = [
    { "complaintId": "1", "name": "Violent or Repulsive Content" },
    { "complaintId": "2", "name": "Hateful or Abusive Content" },
    { "complaintId": "3", "name": "Harmful or Dangerous Acts" },
    { "complaintId": "4", "name": "Harassment or Bullying" },
    { "complaintId": "5", "name": "Inappropriate images or video" },
    { "complaintId": "6", "name": "Illegal & Harmful Post" },
    { "complaintId": "7", "name": "Child Abuse" },
    { "complaintId": "8", "name": "Promotes Terrorism" },
    { "complaintId": "9", "name": "Spam or Misleading" },
    { "complaintId": "10", "name": "Legal Issue" }

];