import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import CardActions from "@mui/material/CardActions";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { makeApiCall } from "../../common/makeApiCall";
import { Link } from "react-router-dom";
import { Sortby } from "./Sortby";
import { useLocation } from "react-router-dom";
// Dialog Start
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Createprofile } from "../../pages/createprofile/Createprofile";
import Toast from "../../common/toastMessage";
import { Categorysort } from "./Categorysort";
import Datefilter from "./Datefilter";
import CloseIcon from '@mui/icons-material/Close';
import { Box, } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Categorylist } from "../../data/categorylist/Categorylist";
import Pagination from '@mui/material/Pagination';
import NomorepostsErrorMsg from "../../components/nomoreposts/Nomoreposts";
import NointernetErrorMsg from "../nointernet/Nointernet";
import FlagIcon from '@mui/icons-material/Flag';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Reportpost from "./Reportpost";
import DOMPurify from 'dompurify';
// Dialog End
export const Maincard = ({ searchCard }) => {
  // =============|REMOVE HTML STYLE REACTQUILL EDITOR|=============>
  const removeBoldItalic = (html) => {
    // Create a DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    // Remove all bold and italic elements
    doc.querySelectorAll('b, strong, i, em').forEach(el => {
      el.replaceWith(...el.childNodes);
    });
    // Return the sanitized HTML
    return doc.body.innerHTML;
  };
  const sanitizeHtml = (html) => {
    // Remove bold and italic elements
    const cleanedHtml = removeBoldItalic(html);
    // Sanitize the cleaned HTML
    return { __html: DOMPurify.sanitize(cleanedHtml) };
  };

  const [complaintList, setComplaintList] = useState([]);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dataAvailable, setDataAvailable] = useState([]); // State to track if data is available for the current page
  // Function to handle page change
  const handleChange = (event, value) => {
    setPage(value);
  };
  // Component 1
  const location = useLocation();
  const toastDetails = location?.state;
  const aboutData = location?.state?.aboutDataOpen;
  const [openToast, setOpenToast] = useState(location?.state?.open ? location?.state?.open : false);
  const [toastMessage, setToastMessage] = useState(location?.state?.message ? location?.state?.message : '');
  const [toastType, setToastType] = useState(location?.state?.type ? location?.state?.type : '');
  const closeToast = () => {
    setOpenToast(false);
  };
  const [openAboutField, setOpenAboutField] = useState(
    aboutData ? aboutData : false
  );
  const OpenAboutField = () => {
    setOpenAboutField(true);
  };

  const CloseAboutField = () => {
    setOpenAboutField(false);
  };
  const handleCloseDialog = () => {
    setOpenAboutField(false);
  };
  //< -- End -- >
  //About View Start


  const [open, setOpen] = useState(false);

  // const handleAboutOpen = (authorName, createdAt) => {
  //   console.log("Author Name:", authorName);
  //   console.log("Created At:", createdAt);
  //   setOpen(true);
  // };
  // const aboutUser = "A user is someone who employs or uses a particular thing, like a user of nicotine or a user of an internet site. Since you are reading this, you are a user of Vocabulary.com. Congrats! To use something is to employ it or operate it, so a user is someone who uses or takes advantage of something.";
  const [formdata, setFormdata] = useState({
    authorName: "",
    about: "",
    createdAt: "",
    avatarColor: "" // Add avatarColor property to store the color
  });
  // console.log("formdata", formdata);

  const handleAboutOpen = (post, color) => {
    // setFormdata(post);
    setFormdata({ ...post, avatarColor: color }); // Spread existing post data and add avatarColor
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //About View End

  const [searchTerm, setSearchTerm] = useState("");

  const [posts, setPosts] = useState("");
  const [postsCategory, setPostsCategory] = useState("");
  //Date filter start
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  //Date filter end
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryValue, setCategoryValue] = useState("Select Category");
  // const [upvoteCount, setUpvoteCount] = useState("");
  // const [viewCount, setViewCount] = useState("");
  const [count, setCount] = useState("")
  const handleSort = (option) => {
    setPostsCategory(option);
    setCategoryValue(option);
    setPage(1);
  };
  // ==================|ALL POST LIST API CALL|=============================>
  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     setIsLoading(true);
  //     try {
  //       let endpoint;
  //       let data = {};
  //       if (searchCard.length > 0) {
  //         console.log("searchCard > 0");
  //         endpoint = '/post/search';
  //         data = {
  //           search: searchCard, // Assuming you have a state variable searchTerm containing the search term
  //         };
  //       } else if (postsCategory != '') {
  //         console.log("postsCategory1", postsCategory);
  //         endpoint = '/post/categorysearch';
  //         data = {
  //           category: postsCategory, // Assuming you have a state variable searchTerm containing the search term
  //         };
  //       } else if (startDate && endDate) {
  //         console.log("DateFilter");
  //         endpoint = '/post/datefilter';
  //         data = {
  //           startDate: startDate,
  //           endDate: endDate
  //         }
  //       } else {
  //         console.log("else listttt");
  //         endpoint = '/post/list';
  //         data = {};
  //       }
  //       const response = await makeApiCall("post", endpoint, data);
  //       console.log("response:", response);
  //       // return 0;
  //       setPosts(response.data.data);
  //     } catch (error) {
  //       // console.error("Error fetching posts:", error);
  //       setError(error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchPosts();
  // }, [searchCard, postsCategory, startDate, endDate]); // Empty dependency array: fetch only once on mount

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await makeApiCall("post", "/post/list",
          {
            pageNo: searchCard ? setPage(1) : page,
            limit: limit,
            search: searchCard || "",
            categorySearch: postsCategory || "",
            organizationSearch: "",
            startDate: startDate || "",
            endDate: endDate || "",
            count: count || ""
            //  upvoteCount: upvoteCount || "",
            //  viewCount: viewCount || ""
          });
        // return 0;1
        setPosts(response.data.data);
        setDataAvailable(response.data);
      } catch (error) {
        // console.error("Error fetching posts:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPosts();
  }, [searchCard, postsCategory, startDate, endDate, count, page, limit]);


  // ============================| SHARE |==============================>
  const handleShare = (id) => {
    const baseUrl = "https://theopenletter.org";

    if (navigator.share) {
      // This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.
      navigator
        .share({
          url: `${baseUrl}/viewpost/${id}`, // Construct the complete URL
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // console.log("Web Share API not supported");
    }
  };
  // const capitalizeTitle = (title) => {
  //   return title
  //     .split(' ')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };
  const capitalizeTitle = (text) => {
    if (!text) return ''; // Handle empty strings gracefully

    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const avatarColors = ['#0866ff', '#34a853', '#E60023', "#f2a600", '#FF00BF',]; // Array of different background colors
  const [openReport, setOpenReport] = useState();
  const [flagId, setFlagId] = useState();
  console.log("flagId", flagId);
  const handleClickOpen = (flagData) => {
    console.log("flagData", flagData);
    setFlagId(flagData);
    setOpenReport(true);
  };
  const handleClickClose = () => {
    setOpenReport(false);
  };

  // =========================| REPORT POST API CALL |===================================>
  const [initialValues, setInitialValues] = useState({
    postId: '',
    complaintId: ''
  });

  const reportSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await makeApiCall('post', '/post/complaint', {
        postId: flagId,
        complaintId: values.complaintId
      });
      console.log('reportsuccess', response.data);
      handleClickClose(); // Corrected function call
    } catch (error) {
      console.error('Error reporting post:', error);
    }
  };
  // useEffect(() => {
  // }, [flagId])
  return (
    <>
      {isLoading ? (
        <Box >
          <LinearProgress />
        </Box>
      ) : (
        <>
          {openAboutField == false ? (
            <>
              <Grid container spacing={1} sx={{ alignItems: "center", }}>
                <Grid item xs={2} sm={4} md={6} lg={6} xl={8}
                  sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', alignSelf: 'center' }}>
                  <Datefilter dateStart={setStartDate} dateEnd={setEndDate} ViewStartDate={startDate} ViewEndDate={endDate} setPage={setPage} />
                </Grid>

                {/* Category Start */}
                <Grid item xs={6} sm={4} md={12} lg={4} xl={3}>
                  < Categorysort xs={{ size: "small" }} postsCategories={setPostsCategory} postsCategory={postsCategory} setPage={setPage} />
                </Grid>
                {/* Category End */}
                <Grid item xs={4} sm={4} md={12} lg={2} xl={1}  >
                  <Sortby xs={{ size: "small" }} counts={setCount} count={count} setPage={setPage} />
                </Grid>
              </Grid>
              {error ? (
                <Box>
                  {/* <Nomoreposts/> */}
                  <NointernetErrorMsg />
                </Box>
              ) : posts.length === 0 ? (
                <Box>
                  {/* No Post Found */}
                  {/* No more posts... */}
                  <NomorepostsErrorMsg />
                </Box>
              ) : (
                posts
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  // .filter(
                  //   (post) =>
                  //     post.title
                  //       .toLowerCase()
                  //       .includes(searchCard.toLowerCase()) ||
                  //       post.message
                  //       .toLowerCase()
                  //       .includes(searchCard.toLowerCase()) ||
                  //     post.category
                  //       .toLowerCase()
                  //       .includes(searchCard.toLowerCase())
                  // )
                  .map((post, index) => (
                    <Grid container sx={{ padding: 1 }}>

                      <Grid item xs={12} key={post.id}>
                        <Card sx={{ textAlign: "left" }}>
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  backgroundColor: avatarColors[index % avatarColors.length],
                                  cursor: "pointer",
                                }}
                                onClick={() => handleAboutOpen(post, post.avatarColor || avatarColors[index % avatarColors.length])}
                              // src="../../assets/icons/profile.png"
                              >
                                {post.authorName.charAt(0).toUpperCase()}
                              </Avatar>
                            }
                            action={
                              <>
                                <Grid container sx={{
                                  display: {
                                    xs: 'none',
                                    sm: 'block',
                                    md: 'block',
                                    lg: 'block',
                                    xl: 'block',
                                  },
                                }}>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    startIcon={<FlagIcon />}
                                    onClick={() => handleClickOpen(post.id)}
                                  >
                                    Report
                                  </Button>
                                </Grid>
                                <Box sx={{
                                  display: {
                                    xs: 'block',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'none',
                                    xl: 'none',
                                  },
                                }}>
                                  <IconButton onClick={() => handleClickOpen(post.id)}>
                                    <FlagIcon fontSize="small" color="error" />
                                  </IconButton>
                                </Box>
                              </>


                            }

                            // action={
                            //   <>
                            //     <Tooltip title={post.state} placement="left" arrow TransitionComponent={Zoom}
                            //       componentsProps={{
                            //         tooltip: {
                            //           sx: {
                            //             bgcolor: '#b60000d6',
                            //             '& .MuiTooltip-arrow': {
                            //               color: '#b60000d6',
                            //             },
                            //           },
                            //         },
                            //       }}
                            //     >
                            //       <IconButton aria-label="settings" size="small">
                            //         <LocationOnIcon />
                            //         {/* <Typography variant="body2" color="text.secondary">
                            //         {post.state}
                            //       </Typography> */}
                            //       </IconButton>
                            //     </Tooltip>

                            //   </>
                            // }

                            // action={
                            //     <IconButton aria-label="settings">
                            //         <Typography variant="body2" color="text.secondary" sx={{
                            //           backgroundColor:'red',
                            //           padding:'5px',
                            //           borderRadius:'15px'
                            //         }} >
                            //   {post.state}
                            //       </Typography>
                            //     </IconButton>
                            // }
                            title={

                              <>
                                <span
                                  //  onClick={() => handleAboutOpen(post)}
                                  onClick={() => handleAboutOpen(post, post.avatarColor || avatarColors[index % avatarColors.length])}
                                  style={{ cursor: 'pointer' }}> {post.authorName} {" "} </span>

                                <span style={{
                                  color: 'gray'
                                }} >
                                  {post.createdAt}
                                </span>
                              </>
                            }

                            subheader={post.district}

                          // onClick={handleAboutOpen}
                          // onClick={() => handleAboutOpen(post.authorName, post.createdAt)}
                          />
                          <CardContent sx={{ textAlign: "left" }}>
                            <Typography gutterBottom variant="h5" sx={{ fontFamily: 'Josefin Sans', }}>
                              <Link to={`/viewpost/${post.id}`} target="_blank">
                                {capitalizeTitle(post.title)}
                              </Link>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {post.message.length > 0 ? (
                                <>
                                  {post.message.length > 100 ? (
                                    <>
                                      <span>
                                        <span dangerouslySetInnerHTML={sanitizeHtml(`${post.message.substring(0, 100)}`)} />
                                        {/* Wrap the "Read More" link in a span for proper styling */}
                                        <span style={{ textDecoration: 'underline', color: 'black' }}>
                                          <Link to={`/viewpost/${post.id}`} target="_blank">
                                            ...Read More
                                          </Link>
                                        </span>
                                      </span>
                                    </>
                                  ) : (
                                    <span dangerouslySetInnerHTML={sanitizeHtml(post.message)} />
                                  )}
                                </>
                              ) : null}
                            </Typography>

                          </CardContent>
                          <CardActions
                            disableSpacing
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Grid container sx={{ padding: 1, gap: 2 }} alignItems="center">
                              <Grid sx={{ height: "25px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid item>
                                  <IconButton
                                    disableRipple
                                    sx={{
                                      // color: "#0866ff",
                                      "&:hover": {
                                        backgroundColor: "inherit", // Ensure background remains consistent
                                        cursor: "default", // Optional: Set cursor to default for non-clickable appearance
                                      },
                                    }}
                                    aria-label="like"
                                  >
                                    <ThumbUpOffAltIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {post.upvoteCount}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid sx={{ height: "25px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid item>
                                  <IconButton
                                    disableRipple
                                    size="small"
                                    sx={{
                                      "&:hover": { cursor: "default" },
                                    }}
                                    aria-label="views"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" color="text.secondary">
                                    {post.viewCount}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              sx={{
                                justifyContent: "end",


                                // display: { xs: 'none', sm: 'flex' }
                              }}
                            >
                              <Grid item>
                                <IconButton
                                  aria-label="share"
                                  // size="small"
                                  // backgroundColor="red"
                                  onClick={() => handleShare(post.id)}
                                >
                                  <ShareIcon size="small" color="primary" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>

                      </Grid>

                    </Grid>
                  ))
              )}
              <>
                {/* {dataAvailable.length > 0 && ( // Render pagination only if data is available for the current page */}

                {/* <Pagination */}

                {/* count={10}  */}
                {/* count={dataAvailable.length > 0 ? 10 : 0} // Render pagination only if data is available for the current page */}
                {/* count={totalPagesWithData} */}
                {/* page={page} onChange={handleChange}  color="primary" />  */}
                {/* )} */}
              </>
              {/* <>
<Pagination
    count={totalPagesWithData}
    page={page} onChange={handleChange}  color="primary" /> 
</> */}
              <>
                <Grid item>
                  {/* {dataAvailable.length > 0 && ( // Render pagination only if data is available for the current page */}
                  <Pagination
                    sx={{ marginTop: 5, marginBottom: 4 }}
                    count={dataAvailable?.pageCount}
                    // count={dataAvailable.length > 0 ? 10 : 0} // Render pagination only if data is available for the current page
                    // count={totalPagesWithData}
                    page={page} onChange={handleChange} color="primary" />
                  {/* )} */}
                </Grid>
              </>

            </>
          ) : (
            <>
              <Dialog
                open={openAboutField}
                // onClose={handleCloseDialog}
                // onClose={CloseAboutField}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <IconButton
                    aria-label="close"
                    onClick={CloseAboutField}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Createprofile
                      header={"About"}
                      openAboutField={setOpenAboutField}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </>
          )}

          {/* <> */}
          {/* <Dialog
          open={openAboutField}
          onClose={handleCloseDialog}
          // onClose={CloseAboutField}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        > */}
          {/* <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }} >

            About
          </DialogTitle> */}
          {/* <DialogContent> */}
          {/* <DialogContentText id="alert-dialog-description"> */}
          {/* <Createprofile /> */}
          {/* </DialogContentText> */}
          {/* </DialogContent> */}
          {/* <DialogActions> */}
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          {/* <Button autoFocus>
              Submit
            </Button> */}
          {/* </DialogActions> */}
          {/* </Dialog> */}
          {/* </> */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px", // Set your width here
                },
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <span style={{ display: "flex", flexDirection: "row" }}>
                <span>{<Avatar
                  // src="../../assets/icons/profile.png"
                  style={{ backgroundColor: formdata.avatarColor || '#ff0000' }} // Default to red if color is not provided
                >
                  {formdata?.authorName.charAt(0).toUpperCase()} </Avatar>}</span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "small",
                    marginLeft: 9,
                  }}
                >
                  <span>{formdata?.authorName}{" "}<span style={{
                    color: 'gray'
                  }}>{formdata?.createdAt}</span></span>
                  <span style={{
                    color: 'gray'
                  }}>{formdata?.district}</span>
                </span>
              </span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" color={'black'}>
                {/* {formdata?.createdAt} */}
                {formdata?.about}
                {/* {aboutUser} */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Disagree</Button> */}
              <Button onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>

        </>
      )
      }
      <>
        {
          openToast && <Toast open={openToast} message={toastMessage} type={toastType} onClose={closeToast} />
        }


      </>
      {
        openReport && <Reportpost setOpenReport={setOpenReport} flagId={flagId} />
      }
    </>
  );
};
