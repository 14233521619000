export const Categorylist = [
    { "id": "0", "name": "Category", disabled: true },
    { "id": "1", "name": "Water Management" },
    { "id": "2", "name": "Pollution Control" },
    { "id": "3", "name": "Chennai Corporation" },
    { "id": "4", "name": "Passenger Amenities" },
    { "id": "5", "name": "Efficient Transport" },
    { "id": "6", "name": "Pedestrian Services" },
    { "id": "7", "name": "Cyclists Needs" },
    { "id": "8", "name": "Bus Services (MTC)" },
    { "id": "9", "name": "To MLA" },
    { "id": "10", "name": "To MP" },
    { "id": "11", "name": "To Councillor" },
    { "id": "12", "name": "To Minister" },
    { "id": "13", "name": "Public Facilities" },
    { "id": "14", "name": "Corruption" },
    { "id": "15", "name": "Law and Order Police Services" },
    { "id": "16", "name": "Traffic Police Services" },
    { "id": "17", "name": "City - Municipalities" },
    { "id": "18", "name": "Government Hospitals" },
    { "id": "19", "name": "RTO - Services" },
    { "id": "20", "name": "Safe FootPath" },
    { "id": "21", "name": "Clean Drinking Water" },
    { "id": "22", "name": "Social Issues" },
    { "id": "23", "name": "Health Matters" },
    { "id": "24", "name": "Sewage Services" },
    { "id": "25", "name": "Rain Water Harvesting" },
    { "id": "26", "name": "Private Companies" },
    { "id": "27", "name": "Revenue Department" },
    { "id": "28", "name": "Social" },
    { "id": "29", "name": "Agriculture" },
    { "id": "30", "name": "Others" }
];
