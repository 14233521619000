import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography, Container, Link } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { makeApiCall } from '../../common/makeApiCall';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CryptoJS from 'crypto-js';
import Toast from '../../common/toastMessage';
// import CryptoJS from 'crypto-js';

export const Signup = () => {
  const navigate = useNavigate();
  // Toast Start

  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');

  // Function to close the toast
  const closeToast = () => {
    setOpenToast(false);
  };

  // Toast End
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    password: '',
  });
  // console.log('initialValues', initialValues);
  const validationSchema = yup.object().shape({
    name: yup.string().matches(
      /^[a-zA-Z0-9\-_\s]+$/, // Allow letters, numbers, hyphen, underscore, and whitespace
      'Name can only contain letters, numbers, hyphens, underscores, and spaces'
    ).min(3, 'name must be at least 3 characters').required('Name is required'),
    email: yup.string().email('Invalid email format').matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      'Email address must be in the format "username@domain.com"')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/\d/, 'Password must contain at least one digit')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[!@#$%^&*()_+\-=\[\]{};':,.<>/?|~`]/, 'Password must contain at least one special character'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      // Encrypt password if required by your API
      // const encryptedPassword = CryptoJS.AES.encrypt(values.password, secretKey).toString();
      let encryptedEmail = CryptoJS.AES.encrypt(values?.email, process.env.REACT_APP_CRYPTO_SECRET_PASSWORD).toString();
      let encryptedPassword = CryptoJS.AES.encrypt(values?.password, process.env.REACT_APP_CRYPTO_SECRET_PASSWORD).toString();
      const response = await makeApiCall('post', 'user/register',
        // values
        {
          name: values?.name,
          email: encryptedEmail,
          password: encryptedPassword,
        }
      );
      // Check for successful registration
      if (response.status === 200) {
        // console.log('successful:', response);
        navigate("/login", {
          state: {
            open: true,
            message: response?.data?.message,
            type: 'success'
          }
        });
        resetForm();
      } else if (response.status === 400) {
        // console.log("signup error", response);
        setOpenToast(true);
        setToastMessage(response?.message);
        setToastType('error');
      } else {
        // Handle other potential errors
        throw new Error('Signup failed. Please try again later.');
      }
    } catch (error) {
      // console.error('Signup error:', error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <>
      <Container component="main" maxWidth="xs" sx={{
        backgroundColor: "white", borderRadius: 3,
        // border: '2px solid #142d55',
        marginTop: "15vh"
      }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            Height: '100vh',
            padding: 5
          }}
        >
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {(formik) => (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        name="name"
                        label="Name"
                        as={TextField}
                        helperText={<ErrorMessage name="name" />}
                        fullWidth
                        margin="normal"
                        error={formik.touched.name && formik.errors.name}

                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="email"
                        label="Email Address"
                        as={TextField}
                        helperText={<ErrorMessage name="email" />}
                        fullWidth
                        margin="normal"
                        error={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        as={TextField}
                        helperText={<ErrorMessage name="password" />}
                        fullWidth
                        margin="normal"
                        error={formik.touched.password && formik.errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
                        Sign Up
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary" align="center">
                        Already have an account?{' '}
                        <Link href="/login" underline="hover">
                          Log In
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Box >
      </Container >
      <>
        {
          openToast && <Toast open={openToast} message={toastMessage} type={toastType} onClose={closeToast} />
        }


      </>
    </>
  );
};

