import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import GlobalTheme from './themes/GlobalTheme';
import { ThemeProvider } from '@mui/material/styles';
// Google Analytics
import ReactGA from "react-ga4";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// google analytics  Measurement ID
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
ReactGA.send(
  {
    hitType: "pageview",
    // URL ROUTES GET IN ANALYTICS
    page: window.location.pathname
    //If need any othere spacific screen report can add the path here
  }
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ThemeProvider theme={GlobalTheme}>
        <ToastContainer />
        <App />

      </ThemeProvider>
    </React.StrictMode>
  </BrowserRouter>
);


reportWebVitals();
