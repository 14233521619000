import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import Toast from "../../common/toastMessage";
import * as Yup from 'yup';
import { makeApiCall } from "../../common/makeApiCall";
import { Formik, Form, } from 'formik';


const mapUrl =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7797.960963430875!2d79.97576!3d12.2496!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5373320d3c7937%3A0xe395933298862b2!2sVSL%20Technologies!5e0!3m2!1sen!2sus!4v1715521704078!5m2!1sen!2sus";
export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  // Toast Start
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const closeToast = () => {
    setOpenToast(false);
  };
  // Toast End
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('email is required'),
    subject: Yup.string().required('subject is required'),
    message: Yup.string().required('message is required')
  });
  const reportSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("value", values)
    try {
      const response = await makeApiCall('post', '/user/send-message', values);
    } catch (error) {
      console.error('Error reporting post:', error);
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <>
      <Container maxWidth="lg">
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={1}>
                      <IconButton disableRipple>
                        <LocationOnIcon />
                      </IconButton>
                      <Grid item xs={12}>
                        <Typography variant="h6">Location:</Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          gutterBottom
                        >
                          ECR, Vennangupattu, Cheyyur Taluk, Chengalpet
                          District, Tamilnadu, India.
                          <br /> Pincode: 603 304
                        </Typography>
                      </Grid>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <IconButton disableRipple>
                        <EmailIcon />
                      </IconButton>
                      <Grid item xs={12}>
                        <Typography variant="h6">Email:</Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          gutterBottom
                        >
                          tn.forum.org@gmail.com
                        </Typography>
                      </Grid>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <IconButton disableRipple>
                        <PhoneIcon />
                      </IconButton>
                      <Grid item xs={12}>
                        <Typography variant="h6">Phone:</Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          gutterBottom
                        >
                          +91 988 408 4836
                        </Typography>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Grid item xs={12}>
                    <Box sx={{ position: "relative", paddingBottom: "56.25%" }}>
                      <iframe
                        src={mapUrl}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          border: 0,
                          borderRadius: 15,
                        }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={reportSubmit}>
                  {(formik) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            type="Email"
                            name="email"
                            id="email"
                            label="Email"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="subject"
                            name="subject"
                            label="Subject"
                            variant="outlined"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="message"
                            label="Message"
                            name="message"
                            variant="outlined"
                            multiline
                            rows={6}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            sx={{ borderRadius: 8, marginTop: 5 }}
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Send Message
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <>
        {openToast && (
          <Toast
            open={openToast}
            message={toastMessage}
            type={toastType}
            onClose={closeToast}
          />
        )}
      </>
    </>
  );
}
