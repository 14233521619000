import React from 'react';
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { customStyles } from '../../../components/tablestyle/Tablestyle';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Rightbar } from '../../../components/rightbar/Rightbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import parse from 'html-react-parser';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeApiCall } from '../../../common/makeApiCall';
import BlockIcon from '@mui/icons-material/Block';
import Toast from '../../../common/toastMessage';
import Addpostmodal from '../../../components/addpostmodal/Addpostmodal';

export const Adminlist = () => {
    const navigate = useNavigate();
    // Toast Start
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();

    // Function to close the toast
    const closeToast = () => {
        setOpenToast(false);
    };

    // Toast End
    const [open, setOpen] = useState(false);
    const [viewDetails, setViewDetails] = useState();
    const handleClickOpen = (data) => {
        setViewDetails(data);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [confirmationType, setConfirmationType] = useState(null);
    const [confirmationId, setConfirmationId] = useState(null);

    const confirmationOpen = (type, id) => {
        setConfirmationType(type);
        setConfirmationId(id);
        setOpenConfirmation(true);
    };

    const confirmationClose = () => {
        setOpenConfirmation(false);
    };

    const [listData, setListData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pending, setPending] = useState(false);
    const columns = [
        // {
        //     name: 'User Name',
        //     selector: row => row.authorName,
        //     width: '100px'
        // },

        {
            name: 'Title',
            selector: row => row.title,
            width: '580px'
        },
        // {
        //     name: 'Message',
        //     selector: row => row.message,
        //     width: '390px'
        // },
        {
            name: 'State',
            selector: row => row.district,
            width: '149px'
        },
        {
            name: 'Report Count',
            selector: row => row.complaintCount,
            width: '150px'
        },
        {
            name: 'View',
            selector: row => row.view,
            width: '90px'
        },
        {
            name: 'Edit',
            selector: row => row.edit,
            width: '90px'
        },
        {
            name: 'Delete',
            selector: row => row.delete,
            width: '90px'
        },
        {
            name: 'Block',
            selector: row => row.block,
            width: '90px'
        },
    ];

    useEffect(() => {
        fetchAdminPosts();
    }, [page, limit]);

    const fetchAdminPosts = async () => {
        let data = {
            page: page,
            limit: limit,
        };
        try {
            setPending(true);
            const response = await makeApiCall ("post", "/post/list", data);
            console.log("response!@", response);
            setListData(response.data.data);
            setTotalRecords(response.data.recordCount);
            setPending(false);
        } catch (error) {
            console.error("error:", error);
        }
    };

    const deletePost = async (value) => {
        try {
            let data = {
                id: value
            }
            const response = await makeApiCall ("post", "/post/delete", data);
            setListData(prevListData => prevListData.filter(post => post.id !== value));
            confirmationClose(false);
            setOpenToast(true);
            setToastMessage(response?.data?.message);
            setToastType('success');
        } catch (error) {
            console.error("error:", error);
        }
    };

    const blockUser = async (value) => {
        try {
            let data = {
                blockedUserId: value
            }
            const response = await makeApiCall ("post", "/user/admin/block", data);
            setListData(prevListData => prevListData.filter(user => user.userId !== value));
            confirmationClose(false);
            setOpenToast(true);
            setToastMessage(response?.data?.message);
            setToastType('success');
        } catch (error) {
            console.error("error:", error);
        }
    };

    const [openAddPost, setOpenAddPost] = useState(false);
    const [updatePost, setUpdate] = useState();

    const handleAddPostOpen = (data) => {
        setUpdate(data);
        setOpenAddPost(true);
    };

    const handleAddPostClose = () => {
        setOpenAddPost(false);
    };

    const list = listData && listData.map((row) => {
        let message = '';
        if (row?.message) {
            message = row.message;
            message = parse(`${message.substring(0, 60)}`);
        }

        return {
            authorName: row?.authorName,
            id: row?.id,
            title: row?.title,
            message: message,
            district: row?.district,
        //     complaintCount:<Button
        //     disableRipple
        //     sx={{
            
        //       textTransform: 'none',
        //       backgroundColor: 'transparent',
        //       '&:hover': {
        //         backgroundColor: 'transparent',
        //         textDecoration: 'underline',
        //         cursor: 'pointer',
        //         boxShadow: 'none', // Removes any shadow on hover
        //       },
        //       '&.MuiButton-clicked': {
        //         backgroundColor: 'transparent', // Change to your desired light color
        //       },
        //     }}
        //     // onClick={handleClick}
        //   >
        //    {row?.complaintCount ? row?.complaintCount : 0}
        //   </Button>,
        complaintCount: row?.complaintCount ? row?.complaintCount : 0,
            view: <VisibilityIcon
                sx={{ fontSize: '20px' }}
                onClick={() => handleClickOpen(row)} />,
            edit: <EditIcon
                sx={{ fontSize: '20px' }}
                onClick={() => handleAddPostOpen(row)} />,
            delete: <DeleteIcon
                sx={{ fontSize: '20px' }}
                onClick={() => confirmationOpen('delete', row.id)} />,
            block: <BlockIcon
                sx={{ fontSize: '20px' }}
                onClick={() => confirmationOpen('block', row.userId)} />
        };
    });

    return (
        <>
            <CssBaseline />
            <Container maxWidth="false">
                <div>
                    <DataTable
                        columns={columns}
                        data={list}
                        customStyles={customStyles}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRecords}
                        paginationPerPage={limit}
                        paginationDefaultPage={page}
                        onChangePage={(page) => setPage(page)}
                        onChangeRowsPerPage={(newLimit) => setLimit(newLimit)}
                        progressPending={pending}
                        dense
                    />
                </div>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span style={{ display: 'flex', flexDirection: 'column', fontSize: 'medium' }}>
                        <span><span style={{ fontWeight: 'bold' }}>ID: </span>{viewDetails?.id}</span>
                        <span><span style={{ fontWeight: 'bold' }}>Title: </span>{viewDetails?.title}</span>
                    </span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span><span style={{ fontWeight: 'bold', color: 'black' }}>Message: </span> {viewDetails && parse(viewDetails.message)}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmation}
                onClose={confirmationClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {confirmationType === 'delete' ? "Are you sure you want to delete?" : "Are you sure you want to block?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmationClose}>No</Button>
                    <Button onClick={() => {
                        if (confirmationType === 'delete') {
                            deletePost(confirmationId);
                        } else if (confirmationType === 'block') {
                            blockUser(confirmationId);
                        }
                    }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {openToast && <Toast open={openToast} message={toastMessage} type={toastType} onClose={closeToast} />}
            {openAddPost && <Addpostmodal open={openAddPost} onClose={handleAddPostClose} updatePost={updatePost} fetchAdminPosts={fetchAdminPosts} />}
        </>
    );
}

export default Adminlist;
