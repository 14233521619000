import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Box, Grid, Typography, Container, Card, Button, CardContent, CardMedia, Divider, } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { makeApiCall } from '../../common/makeApiCall';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Tabs, Tab, AppBar, } from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardActions from '@mui/material/CardActions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Createprofile } from '../createprofile/Createprofile';
import CloseIcon from '@mui/icons-material/Close';
import Addpostmodal from '../../components/addpostmodal/Addpostmodal';
import parse from "html-react-parser";
import LinearProgress from '@mui/material/LinearProgress';
export const Userprofile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [about, setAbout] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  let userName = localStorage.getItem("userName");
  let userEmail = localStorage.getItem("userEmail");

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    // name: userName ? userName : '',
    email: '',
    // password: '',
    // confirmpassword: '',
  });
  const validationSchema = yup.object().shape({
    // name: yup.string().min(3, 'name must be at least 3 characters').required('Name is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
  });
  useEffect(() => {
    // console.log("useEffectAdded")
    let user = localStorage.getItem("token");
    // console.log("userDetailInsideAddPost", user)
    if (!user) {
      navigate("/login");
    }
    // Optionally, fetch initial data or perform actions here upon component mount
  }, []);
  const updateSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const response = await makeApiCall('post', '/user/profileupdate', values);
      // console.log('successful:', response);
      // console.log('Signup successful:', response.status);
      if (response.status === 200) {
        // console.log('successful:', response);
        navigate("/");
        // alert('Updated successful!');
        // resetForm();
      } else if (response.status === 400) {
        // Handle already registered user error
        // alert(response.message);
        // throw new Error(response.message);
      } else {
        // Handle other potential errors
        throw new Error('Signup failed. Please try again later.');
      }
    } catch (error) {
      // console.error('Signup error:', error);
      // alert(error.message); // Display user-friendly error message
    } finally {
      setSubmitting(false);
    }
  };
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // =====================|USER POST API CALL|=============================>
  useEffect(() => {
    // fetchPosts();
    fetchAbout();
  }, []);

  // const fetchPosts = async () => {
  //   let data = {}
  //   // setIsLoading(true);
  //   try {
  //     const response = await makeApiCall('post', '/user/myposts', data);
  //     // console.log('response:', response);
  //     setPosts(response.data.data);

  //   } catch (error) {
  //     // console.error('Error fetching posts:', error);
  //     // setError(error);
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };
  // =====================|USER ABOUT API CALL|=============================>

  const fetchAbout = async () => {
    let data = {}
    setIsLoading(true);
    try {
      const response = await makeApiCall('post', '/user/getaboutlist', data);
      // console.log('response:', response);
      setAbout(response.data.data);

    } catch (error) {
      // console.error('Error fetching posts:', error);
      // setError(error);
    } finally {
      setIsLoading(false);
    }
  };


  // ============================| SHARE FUNCTION |=========================>
  const handleShare = (postId) => {
    // const baseUrl = 'http://openletter.vsltech.xyz'; 
    // Replace 'https://example.com' with your server URL
    const baseUrl = 'https://theopenletter.org';
    if (navigator.share) { // Check if Web Share API is supported
      navigator.share({
        url: `${baseUrl}/viewpost/${postId}` // Construct the complete URL
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      // console.log('Web Share API not supported');
    }
  };

  // const handleEditClick = (data) => {
  //   navigate("/", {
  //     state: {
  //       openEdit:true,
  //       data: data
  //     }
  //   })
  // }
  const [openAddPost, setOpenAddPost] = useState(false);
  const [updatePost, setUpdate] = useState();
  // console.log("openAddpost", openAddPost);
  const handleAddPostOpen = (data) => {
    setUpdate(data);
    setOpenAddPost(true);
  };
  const handleAddPostClose = () => {
    setOpenAddPost(false);
  };
  return (
    <>
      <Container component="main">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card sx={{ border: "none" }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar sx={{
                  width: 70, height: 70, padding: 1
                  // backgroundColor:"#404040"
                }}
                  // src="profile.png" 
                  // src={`${process.env.PUBLIC_URL}/profile.png`}
                  src={`${process.env.PUBLIC_URL}/profilelogo.svg`}
                />
                {/* {console.log("process.env.PUBLIC_URL", process.env.PUBLIC_URL)} */}
                <CardContent>
                  <Typography variant="h5" component="div">
                    {userName}
                    {/* <IconButton size='' onClick={handleOpen}>
                    <EditIcon />
                  </IconButton> */}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {userEmail}
                  </Typography>
                </CardContent>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  {/* <Tab label="My Posts" value="1" /> */}
                  <Tab label="About" value="1" />
                </TabList>
              </Box>
              {/* <TabPanel value="1">
                <Grid container spacing={2}>
                  {posts && posts.map((post) => (
                    <Grid item xs={12} sm={12} md={12} key={post.id}>
                      <Card sx={{ textAlign: "left" }}>
                        <CardHeader
                          avatar={<Avatar
                          
                            sx={{ backgroundColor: "#404040" }}
                          > {post?.authorName.charAt(0).toUpperCase()}</Avatar>}
                          action={
                            <>
                              <IconButton aria-label="settings" size="small" onClick={() => handleAddPostOpen(post)}>
                                <EditIcon />
                              </IconButton>

                            </>
                          }
                    
                          title={
                            <>
                              {post?.authorName} .{" "}
                              <span style={{
                                color: 'gray'
                              }} >
                                {post?.createdAt}
                              </span>
                            </>
                          }

                          subheader={post?.state}
                        />
                        <CardContent sx={{ textAlign: 'left' }}>
                          <Typography gutterBottom variant="h6">
                            {post?.title}
                          </Typography>
                          <Typography gutterBottom variant='body2'>
                          {post?.message?.length > 0 ? (
                                <>
                                  {post?.message?.length > 100 ? (
                                    <>
                                      {parse(`${post?.message?.substring(0, 100)}...`)}
                                    </>
                                  ) : (
                                    <>
                                      {parse(post?.message)}
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                          </Typography>
                        </CardContent>
                        <CardActions disableSpacing sx={{ justifyContent: 'space-between' }}>
                          <Grid container spacing={0.7} alignItems="center" flexWrap="nowrap">

                            <Grid item>
                              <IconButton aria-label="like">
                                <ThumbUpOffAltIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" >
                                {post.upvoteCount}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton aria-label="views">
                                <VisibilityIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" >
                                {post.viewCount}

                          
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="center" sx={{
                            justifyContent: "end"
                          }}>
                            <Grid item onClick={() => handleShare(post.id)}>
                              <IconButton aria-label="share" >
                                <ShareIcon color='primary' />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </TabPanel> */}
              {/* <TabPanel value="2">Posts</TabPanel> */}
              {/* <TabPanel value="3">About</TabPanel> */}
              <TabPanel value='1'>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12} md={12}
                  //  key={post.id}
                  >
                    {isLoading ? (
        <Box >
          <LinearProgress />
        </Box>
      ) : (
                    <Card sx={{ textAlign: "left" }}>
                      {/* <CardHeader
                        avatar={<Avatar src="../../assets/icons/profile.png" />}
                        // action={
                        //   <>
                        //     <IconButton aria-label="settings" size="small"
                        //     //  onClick={() => handleEditClick(post)}
                        //     >
                        //       <EditIcon
                        //       // onClick={() => handleEditOpen()}
                        //       />
                        //     </IconButton>

                        //   </>
                        // }
                        title={userName}
                        subheader={userEmail}
                      /> */}

                      <Divider />
                      <CardContent sx={{ textAlign: 'left' }}>
                        <Typography gutterBottom >
                          {about?.about ? about?.about : 'NA'}
                        </Typography>

                      </CardContent>

                      <CardActions
                        disableSpacing
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Grid container spacing={0.7} alignItems="center">
                          <Grid item>
                            <IconButton disableRipple sx={{
                              "&:hover": {
                                backgroundColor: "inherit", // Ensure background remains consistent
                                cursor: "default", // Optional: Set cursor to default for non-clickable appearance
                              },
                            }} aria-label="like">
                              {/* <ThumbUpOffAltIcon color="primary" /> */}
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" color="text.secondary">
                              {/* {post.upvoteCount} */}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton disableRipple sx={{
                              "&:hover": {
                                backgroundColor: "inherit", // Ensure background remains consistent
                                cursor: "default", // Optional: Set cursor to default for non-clickable appearance
                              },
                            }} aria-label="views">
                              {/* <VisibilityIcon color="success" /> */}
                            </IconButton>
                          </Grid>
                          <Grid item >
                            <Typography variant="body2" color="text.secondary">
                              {/* {post.viewCount} */}
                              {/* {post.viewCount !== undefined ? (post.viewCount === 0 ? '0' : post.viewCount) : 'NA'} */}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          sx={{
                            justifyContent: "end",
                            // display: { xs: 'none', sm: 'flex' }
                          }}
                        >
                          <Grid item>
                            <IconButton
                              aria-label="settings" size="small"
                            // onClick={() => handleShare(post.id)}
                            onClick={() => handleEditOpen()}
                            >
                              <EditIcon  />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardActions>

                    </Card>
      )}
                  </Grid>

                </Grid>

              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
      <>

        {
          openEdit &&
          <Dialog
            open={openEdit}
            // onClose={handleCloseDialog}
            // onClose={CloseAboutField}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <IconButton
                aria-label="close"
                onClick={handleEditClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Createprofile
                  header={"About Edit"}
                  openAboutField={setOpenEdit}
                  fetchAbout={fetchAbout} // Pass fetchAbout function as a prop
                  about={about?.about ? about?.about : 'NA'}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>

        }

      </>
      {/* <>
        {openAddPost && <Addpostmodal open={openAddPost} onClose={handleAddPostClose} updatePost={updatePost} fetchPosts={fetchPosts} />}
      </> */}
    </>
  )
}

// css style----------------------->
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
