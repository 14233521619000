import React, { useState } from 'react';
import { Leftbar } from '../../components/leftbar/Leftbar'
import { Mainpost } from '../../components/mainpost/Mainpost'
import { Rightbar } from '../../components/rightbar/Rightbar'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Readpost } from '../../components/mainpost/Readpost';

export const Viewpost = () => {
    return (
        <>
            <CssBaseline />
            <Container maxWidth="false" > {/* Set maximum width for larger screens */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ overflowY: 'auto' }}>
                        <Readpost />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5} sx={{ position: 'static', }}>
                        <Rightbar />
                    </Grid> */}
                </Grid>
            </Container>
        </>
    )
}
