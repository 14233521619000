import React, { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

function Toast(props) {
    // console.log("props", props);
  // const [openToast, setOpenToast] = useState(props?.open ? props?.open  : '');
  // const [toastMessage, setToastMessage] = useState(props?.message ? props?.message : '');
  // const [toastType, setToastType] = useState(props?.type ? props?.type : '');
  // const handleClose = () => {
  //   setOpenToast(false);
  //   if (props.onClose) {
  //     props.onClose(); // Call the onClose function passed from the parent
  //   }
  // };

  const vertical = "top";
  const horizontal = "right";
  return (
    <div>
      {/* <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openToast}
        // autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert
        variant="filled"
          // onClose={() => setOpenToast(false)}
          onClose={handleClose}
          severity={toastType ? toastType : "info"}
          sx={{ width: "100%" }}
        >
          {toastMessage ? toastMessage : "Sorry, Something went wrong!"}
        </Alert>
      </Snackbar> */}
    </div>
  );
}

export default Toast;
