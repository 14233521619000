import React from 'react'
import { Box } from '@mui/material';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
export default function NomorepostsErrorMsg() {
  return (
    <Container 
    maxWidth="sm" 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '70vh' 
    }}
  >
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <SmsFailedIcon sx={{ fontSize: 45, color: '#cc0000' }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center"  >
          No More Posts...
        </Typography>
      </Grid>
    </Grid>
  </Container>
  )
}
