import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

export default function Updates() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
            <Box sx={{ width: '90%', padding: 1 }}>
                <Card sx={{ textAlign: 'left', padding: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Updates
                        </Typography>
                    </Box>
                    <Typography variant="body1">
                        The OpenLetter amplifies your voice, empowering you to address those in power with boldness and clarity. Join a community of changemakers, where every message sparks action and fuels progress. Together, let's shape the future and drive impactful change in our society.
                    </Typography>
                </Card>
            </Box>
        </Box>
    )
}
