import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Make sure to import the DatePicker CSS
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
//Covert date YYYY/MM/DD format - second method
// import { format } from 'date-fns'; // Import the format function
export default function Datefilter({dateStart, dateEnd, ViewStartDate, ViewEndDate, setPage }) {
  const formattedStartDate = ViewStartDate && dayjs(ViewStartDate).format('DD/MM/YYYY');
  const formattedEndDate = ViewEndDate && dayjs(ViewEndDate).format('DD/MM/YYYY');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust the breakpoint as needed
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null); // Set endDate to null initially
  const [open, setOpen] = useState(false); 
  const [buttonText, setButtonText] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    //Convert the date format - yyyy-MM-dd 
    const formatDate = (date) => {
      //Covert date YYYY/MM/DD format - first method
      return date ? date.toISOString().split('T')[0] : null;
       //Covert date YYYY/MM/DD format - second method
      // return date ? format(date, 'yyyy-MM-dd') : null;
    };


  //Getting the start and end date 
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // if (start && end) {
    //   handleClose();
    //   dateStart(formattedStartDate);
    //   dateEnd(formattedEndDate);
    // }
    if (start && end) {
      const formattedStartDate = formatDate(start);
      const formattedEndDate = formatDate(end);
      dateStart(formattedStartDate);
      dateEnd(formattedEndDate);
      setButtonText(`${formattedStartDate} - ${formattedEndDate}`);
      handleClose();
      setPage(1);
    }
  };


  // const formattedStartDate = formatDate(startDate);
  // const formattedEndDate = formatDate(endDate);


  return (
    <>
      {/* <Button variant="text" size="small" onClick={handleClickOpen} disableRipple
       sx={{
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:active': {
          backgroundColor: 'transparent', // Keep background transparent on click
          boxShadow: 'none',              // Remove any box shadow
        },
        '&:focus': {
          backgroundColor: 'transparent',
        } 
      }}
      > */}
   {/* <CalendarMonthIcon /> */}
   {/* </Button> */}

  {/* <Button variant="text" size="small" onClick={handleClickOpen} disableRipple
       sx={{
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:active': {
          backgroundColor: 'transparent', // Keep background transparent on click
          boxShadow: 'none',              // Remove any box shadow
        },
        '&:focus': {
          backgroundColor: 'transparent',
        } 
      }}
      > 
   <CalendarMonthIcon />
   </Button>

   <TextField id="outlined-basic"
    // disabled={!ViewStartDate}
    readonly
    fullWidth variant="outlined" size="small" value={`${ViewStartDate || "DD/MM/YYYY"} - ${ViewEndDate || "DD/MM/YYYY"}`}  InputProps={{
        endAdornment: (
          <CalendarMonthIcon />
        ),
      }}/> */}
{isMobile ? (
        <Button
          variant="text"
          size="small"
          onClick={handleClickOpen}
          disableRipple
          sx={{
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            '&:focus': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <CalendarMonthIcon />
        </Button>
      ) : (
        <TextField
          id="outlined-basic"
          readOnly
          // disabled
          sx={{
            width: '240px',
            cursor: 'pointer',
            marginLeft: '9px',
            '& .MuiOutlinedInput-root': {
              cursor: 'pointer',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray',
            },
            '& .MuiInputAdornment-root': {
              cursor: 'pointer',
            }
          }}
          // width="90px"
          // fullWidth
          variant="outlined"
          size="small"
          value={`${formattedStartDate || "Start Date"} - ${formattedEndDate || "End Date"}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end"   onClick={handleClickOpen}>
                <CalendarMonthIcon
                  sx={{
                    color: 'blue',
                    cursor: 'pointer'
                  }}
                />
              </InputAdornment>
            ),
            style: {
              cursor: 'pointer' // Apply cursor style to the input element
            }
          }}
        />
      )}
{/* <OutlinedInput
            id="outlined-adornment-password"
            value={`${ViewStartDate || "StartDate"} - ${ViewEndDate || "EndDate"}`}
            size="small"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  // onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                 <CalendarMonthIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          /> */}
   {/* {buttonText} */}
      {/* </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{ `${formattedStartDate || "Start Date"} - ${formattedEndDate || "End Date"}` || "Select Date" }</DialogTitle>
        <DialogContent>
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            // onChange={(date) => setStartDate(date)}
            selectsRange
            inline
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}