import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import data from "./communitydata.json"
import { useEffect } from 'react';


export default function CommunityGuidelines() {
    return (
        <Box sx={{ display: 'flex', }}>
            <Box sx={{ width: '100%', padding: 1 }}>
                <Card sx={{ textAlign: 'left', padding: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h4" gutterBottom>
                            Community Guidelines
                        </Typography>
                    </Box>
                    {data.map((item, index) => (
                        <div key={index} >
                            <Typography variant="h5" gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography variant="body1" sx={{ ml: 4, mb: 5 }}>
                                {item.description}
                            </Typography>
                        </div>
                    ))}
                </Card>
            </Box>
        </Box>
    )
}
