import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Maincard } from "./Maincard";
import { useNavigate } from "react-router-dom";
// ============================|EXPORT FUNCTION START|===================================//
export const Mainpost = ({ searchPost }) => {
    return (
        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            <Box sx={{ textAlign: "center", padding: 1 }}>
                {/* <Link to={redirectUrl}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', borderRadius: 4, padding: 1, height: 40, mb: 1, backgroundColor: "#b7e3fc" }}>
                        <AddCircleOutlineIcon fontSize="medium" color="primary" sx={{ mr: 1, }} />
                        <Typography component="h1">
                            Add a post...
                        </Typography>
                    </Box>
                </Link> */}
                <Box>
                    <Maincard searchCard={searchPost} />
                </Box>
            </Box>
        </Box>

    );
};
