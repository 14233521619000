export const Tamilnadudistrict = [
    { "id": "EM", "name": "Select District", disabled: true },
    { "id": "AN", "name": "Ariyalur" },
    { "id": "AN", "name": "Chengalpattu" },
    { "id": "AP", "name": "Chennai" },
    { "id": "AR", "name": "Coimbatore" },
    { "id": "AS", "name": "Cuddalore" },
    { "id": "BR", "name": "Dharmapuri" },
    { "id": "CH", "name": "Dindigul" },
    { "id": "CG", "name": "Erode" },
    { "id": "DN", "name": "Kallakurichi" },
    { "id": "DL", "name": "Kancheepuram" },
    { "id": "GA", "name": "Karur" },
    { "id": "GJ", "name": "Krishnagiri" },
    { "id": "HR", "name": "Madurai" },
    { "id": "HP", "name": "Mayiladuthurai" },
    { "id": "JK", "name": "Nagapattinam" },
    { "id": "JH", "name": "Nagercoil" },
    { "id": "KA", "name": "Namakkal" },
    { "id": "KL", "name": "Perambalur" },
    { "id": "LA", "name": "Pudukkottai" },
    { "id": "LD", "name": "Ramanathapuram" },
    { "id": "MP", "name": "Ranipet" },
    { "id": "MH", "name": "Salem" },
    { "id": "MN", "name": "Sivagangai" },
    { "id": "ML", "name": "Tenkasi" },
    { "id": "MZ", "name": "Thanjavur" },
    { "id": "NL", "name": "Theni" },
    { "id": "OR", "name": "Thiruvallur" },
    { "id": "PY", "name": "Thiruvarur" },
    { "id": "PB", "name": "Thoothukudi" },
    { "id": "RJ", "name": "Tiruchirappalli" },
    { "id": "SK", "name": "Tirunelveli" },
    { "id": "TN", "name": "Tirupathur" },
    { "id": "TG", "name": "Tiruppur" },
    { "id": "TG", "name": "Tiruvannamalai" },
    { "id": "TR", "name": "Udagamandalam (Ootacamund)" },
    { "id": "UP", "name": "Vellore" },
    { "id": "UT", "name": "Viluppuram" },
    { "id": "WB", "name": "Virudhunagar" }
];
