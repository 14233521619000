import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// const apiBaseUrl = "https://api.theopenletter.org/api/v1";


async function makeApiCall(method, url, data = null, successMessage = '', errorMessage = '') {
  const token = localStorage.getItem('token');
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '', // Include Authorization header only if token exists
    },
  });

  try {
    const response = await api[method](url, data);
    if (response?.data?.message !== "Post list retrieved successfully !!" 
    && response?.data?.message !=="Get about list retrieved successfully!"
     &&  response?.data?.message !=="Post list retrieved successfully!"
     &&  response?.data?.message !=="Get post details retrieved successfully !!"
     &&  response?.data?.message !=="Post upvote count updated successfully !!") {
      toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000, // Close toast after 2 seconds (2000 milliseconds)
          hideProgressBar: true, // Hide progress bar
      });
  }
  
    

    // console.log("response", response.data.message)
    return {
      data: response.data,
      status: response.status,

    };
  } catch (error) {
    // console.log("error?.response?.data?.message", error?.response?.data?.errorMessage?.msg);
    toast.error(error?.response?.data?.errorMessage?.msg, {
      position: "top-right",
      autoClose: 2000, // Close toast after 2 seconds (2000 milliseconds)
      hideProgressBar: true, // Hide progress bar
  });
    if (error?.response?.status == 401) {
      localStorage.clear();
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2000, // Close toast after 2 seconds (2000 milliseconds)
        hideProgressBar: true, // Hide progress bar
    });
    window.location.reload(); // Reload the page
    }
    return {
      data: error.response.data,
      message: error.response.data.errorMessage.msg,
      status: error.response.status,
    };
    // handleApiError(error, errorMessage); // Use the error handling function
  }
}


export { makeApiCall };
