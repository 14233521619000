import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { makeApiCall } from "../../common/makeApiCall";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import Addpostmodal from "../../components/addpostmodal/Addpostmodal";
import DOMPurify from 'dompurify';
import LinearProgress from "@mui/material/LinearProgress";
import NomorepostsErrorMsg from "../../components/nomoreposts/Nomoreposts";
export default function Userpost() {
  // =============|REMOVE HTML STYLE REACTQUILL EDITOR|=============>
  const removeBoldItalic = (html) => {
    // Create a DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    // Remove all bold and italic elements
    doc.querySelectorAll('b, strong, i, em').forEach(el => {
      el.replaceWith(...el.childNodes);
    });
    // Return the sanitized HTML
    return doc.body.innerHTML;
  };
  const sanitizeHtml = (html) => {
    // Remove bold and italic elements
    const cleanedHtml = removeBoldItalic(html);
    // Sanitize the cleaned HTML
    return { __html: DOMPurify.sanitize(cleanedHtml) };
  };
  const [isLoading, setIsLoading] = useState(false);
  // console.log("isLoading", isLoading);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [about, setAbout] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };
  // =====================|USER POST API CALL|=============================>
  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    let data = {};
    setIsLoading(true);
    try {
      const response = await makeApiCall("post", "/user/myposts", data);
      // console.log('response:', response);
      setPosts(response?.data?.data);
    } catch (error) {
      // console.error('Error fetching posts:', error);
      // setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // ============================| SHARE FUNCTION |=========================>
  const handleShare = (postId) => {
    // const baseUrl = 'http://openletter.vsltech.xyz';
    // Replace 'https://example.com' with your server URL
    const baseUrl = "https://theopenletter.org";
    if (navigator.share) {
      // Check if Web Share API is supported
      navigator
        .share({
          url: `${baseUrl}/viewpost/${postId}`, // Construct the complete URL
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      // console.log('Web Share API not supported');
    }
  };

  // const handleEditClick = (data) => {
  //   navigate("/", {
  //     state: {
  //       openEdit:true,
  //       data: data
  //     }
  //   })
  // }
  const [openAddPost, setOpenAddPost] = useState(false);
  const [updatePost, setUpdate] = useState();
  // console.log("openAddpost", openAddPost);
  const handleAddPostOpen = (data) => {
    setUpdate(data);
    setOpenAddPost(true);
  };
  const handleAddPostClose = () => {
    setOpenAddPost(false);
  };
  return (
    <>
      <>
        {isLoading ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : (


          <Grid container sx={{ padding: 2 }} spacing={1}>
            <Grid item xs={12} sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ padding: 2, alignSelf: 'start' }}>
                My Posts
              </Typography>
            </Grid>
            {posts?.length === 0 && (
              <Grid item xs={12}>
                <Box>
                  <NomorepostsErrorMsg />
                </Box>
              </Grid>
            )}
            {posts && posts.map((post) => (
              <Grid item xs={12} sm={12} md={12} key={post?.id}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ backgroundColor: "#404040" }}>
                        {post?.authorName.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    action={
                      <IconButton
                        aria-label="settings"
                        size="small"
                        onClick={() => handleAddPostOpen(post)}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                    title={
                      <>
                        {post?.authorName} .{" "}
                        <span style={{ color: "gray" }}>
                          {post?.createdAt}
                        </span>
                      </>
                    }
                    subheader={post?.district}
                  />
                  <CardContent sx={{ textAlign: "left" }}>
                    <Typography gutterBottom variant="h6">
                      {post?.title}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      {post?.message?.length > 0 ? (
                        <>
                          {post?.message?.length > 100 ? (
                            <>
                              <span dangerouslySetInnerHTML={sanitizeHtml(`${post.message.substring(0, 100)}...`)} />
                            </>
                          ) : (
                            <span dangerouslySetInnerHTML={sanitizeHtml(post.message)} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing sx={{ justifyContent: "space-between" }}>
                    <Grid container spacing={0.7} alignItems="center" flexWrap="nowrap">
                      <Grid item>
                        <IconButton aria-label="like">
                          <ThumbUpOffAltIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {post?.upvoteCount}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton aria-label="views">
                          <VisibilityIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {post?.viewCount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                      <Grid item onClick={() => handleShare(post?.id)}>
                        <IconButton aria-label="share">
                          <ShareIcon color="primary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

        )}
      </>
      <>
        {openAddPost && (
          <Addpostmodal
            open={openAddPost}
            onClose={handleAddPostClose}
            updatePost={updatePost}
            fetchPosts={fetchPosts}
          />
        )}
      </>
    </>
  );
}
