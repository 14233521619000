import "./App.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Navbar } from "./components/navbar/Navbar";
import { Home } from "./pages/home/Home";
import { Login } from "./pages/login/Login";
import { Signup } from "./pages/signup/Signup";
import { Userprofile } from "./pages/userprofile/Userprofile";
import { Viewpost } from "./pages/viewpost/Viewpost";
import { Pagenotfound } from "./pages/pagenotfound/Pagenotfound";
import { useEffect } from "react";
import { Createprofile } from "./pages/createprofile/Createprofile";


function App() {
  let user = localStorage.getItem("token");
  const location = useLocation();

  // Determine if Navbar should be displayed based on path
  const showNavbar = !["/login", "/signup", "/createprofile"].includes(location.pathname);
  return (
    <>

      <>
        {showNavbar && <Navbar />}
        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/createprofile" element={<Createprofile />} />
        </Routes>
      </>
    </>
  );
}

export default App;
