import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Addpostmodal from "../addpostmodal/Addpostmodal";
import {
    Typography,
    IconButton,
} from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useTheme } from '@mui/material/styles';


export default function Addpostfield() {
    let user = localStorage.getItem("token");
    const theme = useTheme();
    const navigate = useNavigate();

    const [openAddPost, setOpenAddPost] = useState(false);
    // console.log("openAddpost", openAddPost);
    const handleAddPostOpen = () => {
        if (user) {
            setOpenAddPost(true);
        } else {
            navigate('/login')
        }
    };
    const handleAddPostClose = () => {
        setOpenAddPost(false);
    };
    return (
        <>

            <Box
                sx={{
                    display: {
                        xs: 'none', // Visible on extra-small devices (phones)
                        sm: 'block', // Hidden on small devices (tablets)
                        md: 'block', // Hidden on medium devices (tablets)
                        lg: 'block', // Hidden on large devices (desktops)
                        xl: 'block', // Hidden on extra-large devices (large desktops)
                    },
                }}
            >

                <Typography component="h1" onClick={handleAddPostOpen} sx={{
                    display: "flex", alignItems: "center", cursor: "pointer",
                    borderRadius: 1,
                    paddingLeft: 1,
                    height: "37px",
                    width: 250,
                    backgroundColor: "#96d9ff",
                }}>  <AddCircleOutlineIcon
                        fontSize="medium"
                        color="primary"

                        sx={{ mr: 1, fontStyle: "bold" }}
                    />
                    Write an Open Letter...</Typography>
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'block', // Visible on extra-small devices (phones)
                        sm: 'none', // Hidden on small devices (tablets)
                        md: 'none', // Hidden on medium devices (tablets)
                        lg: 'none', // Hidden on large devices (desktops)
                        xl: 'none', // Hidden on extra-large devices (large desktops)
                    },
                }}
            >
                <IconButton onClick={handleAddPostOpen}>
                    <EditNoteIcon fontSize="large" />
                </IconButton>
            </Box>

            {openAddPost && <Addpostmodal open={openAddPost} onClose={handleAddPostClose} />}
        </>
    )
}
