import React, { useState } from 'react'
import { Select, MenuItem, FormControl, Grid } from '@mui/material';
import { Categorylist } from '../../data/categorylist/Categorylist';


export const Categorysort = ({ postsCategories, postsCategory, setPage }) => {
    const [categoryValue, setCategoryValue] = useState(postsCategory || "Category");

    const handleSort = (option) => {
        postsCategories(option);
        setCategoryValue(option);
        setPage(1);
    };
    return (
        <>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end", mr: 2 }}
                >
                    <FormControl sx={{ minWidth: 100 }}>
                        <Select
                            sx={{ height: 30, width: 140 }}
                            value={categoryValue}
                            onChange={(e) => handleSort(e.target.value)}
                            size="small"
                            displayEmpty
                        >
                            {" "}
                            {Categorylist.map((option) => (
                                <MenuItem
                                    sx={{ display: "flex", flexwrap: "wrap" }}
                                    key={option.id}
                                    value={option.name}
                                    disabled={option.disabled}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};