import React, { useState } from 'react'
import { Select, MenuItem, FormControl, Grid } from '@mui/material';

const options = [
  { value: 'option1', label: 'Sort by' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];
export const Sortby = ({ counts, count,setPage }) => {
  //Sorting by Likes and Views start
  const [sortBy, setSortBy] = useState(count ? count : "");
  const handleSort = (option) => {
    setSortBy(option);
    counts(option);
    setPage(1);
  };
  //Sorting by Likes and Views end
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end", padding: 1 }}
        >
          <FormControl sx={{ minWidth: 100 }}>
            <Select
              sx={{ height: 30, width: 100 }}
              value={sortBy}
              onChange={(e) => handleSort(e.target.value)}
              size="small"
              displayEmpty
            >
              {" "}
              <MenuItem value={""} disabled>
                Sort by
              </MenuItem>
              <MenuItem value={"upvoteCount"}>Most Liked</MenuItem>
              <MenuItem value={"viewCount"}>Most Viewed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};