import React, { useState, useCallback, useRef, } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { makeApiCall } from '../../common/makeApiCall';
import { useNavigate, useLocation } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { Createprofile } from '../createprofile/Createprofile';
import CryptoJS from 'crypto-js';
import Toast from '../../common/toastMessage';
import CircularProgress from "@mui/material/CircularProgress";
// CUSTOMIZE ANY UI BUTTON
// import { FacebookLoginButton, } from 'react-social-login-buttons'
// import CryptoJS from 'crypto-js';
// const secretKey = 'N1ger1@'

export const Login = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const googleRef = useRef(null);
  const about = 'remove';
  const [open, setOpen] = useState(true);
  //Getting the user aboutUs data from the Createprofile component 
  //< -- Start -- > 
  const location = useLocation();
  const aboutData = location?.state?.aboutData;
  // console.log("aboutData", aboutData);
  //< -- End -- > 
  // Toast Start

  const [openToast, setOpenToast] = useState(location?.state?.open ? location?.state?.open : false);
  const [toastMessage, setToastMessage] = useState(location?.state?.message ? location?.state?.message : '');
  const [toastType, setToastType] = useState(location?.state?.type ? location?.state?.type : '');

  // Function to close the toast
  const closeToast = () => {
    setOpenToast(false);
  };
  // console.log("location?.state?.open", location?.state?.open);

  // Toast End
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
  });
  // const cryptoSecretPassword = process.env.REACT_APP_CRYPTO_SECRET_PASSWORD;
  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email format').matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      'Email address must be in the format "username@domain.com"')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
    // .min(8, 'Password must be at least 8 characters long')
    // .matches(/\d/, 'Password must contain at least one digit')
    // .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    // .matches(/[!@#$%^&*()_+\-=\[\]{};':,.<>/?|~`]/, 'Password must contain at least one special character'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true); // Simulate form submission (replace with actual API call)

    // return 0;
    try {
      // Encrypt the password before sending it to the API
      // const encryptedPassword = CryptoJS.AES.encrypt(values.password, secretKey).toString();
      let encryptedEmail = CryptoJS.AES.encrypt(values?.email, process.env.REACT_APP_CRYPTO_SECRET_PASSWORD).toString();
      let encryptedPassword = CryptoJS.AES.encrypt(values?.password, process.env.REACT_APP_CRYPTO_SECRET_PASSWORD).toString();
      const response = await makeApiCall(
        'post',
        'user/login', // Replace with your actual API endpoint
        // values,
        {
          email: encryptedEmail,
          password: encryptedPassword
        }
        // { email: values.email, password: encryptedPassword },
       
      );
      // Store token and user name in local storage
      if (response.status === 200) { // Assuming success status code
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userType', response.data.userDetail.userType);
        localStorage.setItem('aboutEnable', response.data.userDetail.aboutEnable);
        localStorage.setItem('userName', response.data.userDetail.name);
        localStorage.setItem('userEmail', values.email); // Store email from form data
        // navigate("/");
        if (response.data.userDetail.userType === "AD") {
          navigate("/adminlist");
        } else {
          if (response?.data?.userDetail?.aboutEnable === 0) {
            navigate("/"
              , { state: { aboutDataOpen: open } }
            );
          } else if (response?.data?.userDetail?.aboutEnable === 1) {
            navigate("/");
          } else {

          };
        }

        // navigate("/", { state: { aboutData: aboutData ? aboutData : "" } }); // Pass aboutData to the dashboard screen
      } else {
        // console.error('Login unsuccessful:', response);
        setOpenToast(true);
        setToastMessage(response?.message);
        setToastType('error');

      }
      resetForm();
    } catch (error) {
      // console.error('Login error:', error);
      // Handle login error here
      if (error.response && error.response.status === 400) {
        navigate("/signup", { state: { errorMessage: 'Invalid email or password' } });
      } else {
        // console.error('Login unsuccessful');
      }
    } finally {
      setSubmitting(false); // Always reset the loading state
    }
  };



  // const onLoginStart = useCallback(() => {
  //   alert("login start");
  // }, []);

  // const onLogoutFailure = useCallback(() => {
  //   alert("logout fail");
  // }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
  }, []);


  const googlelogin = async (data) => {
    try {
      setIsLoading(true);
      const response = await makeApiCall(
        'post',
        'user/googlelogin',
        { accessToken: data.access_token });

      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userName', response.data.userDetail.name);

        if (response.data.userDetail.userType === "AD") {
          navigate("/adminlist");
        } else {
          if (response.data.userDetail.aboutEnable === 0) {
            // navigate("/aboutuser");
            navigate("/"
            , { state: { aboutDataOpen: open } }
          );

          } else if (response.data.userDetail.aboutEnable === 1) {
            navigate("/");
          }
          setIsLoading(false);
        }
      } else {
      }
    } catch (error) {
    }
  };
  return (
    <>
      <Container component="main" maxWidth="xs" sx={{
        backgroundColor: "white", borderRadius: 3,
        // border: '2px solid #142d55',
        marginTop: "15vh"



      }}>
        <Box
          sx={{

            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            Height: '100vh',
            padding: 5
          }}
        >
          <Typography component="h1" variant="h5">
            Log In
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {(formik) => (
                <Form>
                  <Grid container spacing={1}>
                    {/* <Grid item xs={12}>
                      <Field
                        name="email"
                        label="Email Address"
                        as={TextField}
                        helperText={<ErrorMessage name="email" />}
                        fullWidth
                        margin="normal"
                        type="email"
                        error={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        as={TextField}
                        helperText={<ErrorMessage name="password" />}
                        fullWidth
                        margin="normal"
                        error={formik.touched.password && formik.errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
                        Sign In
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary" align="center">
                        Not a member?{' '}
                        <Link href="/signup" underline="hover">
                          Sign Up
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Divider
                          variant="middle"
                          sx={{ flexGrow: 1, mt: 1 }}
                          style={{ position: 'relative' }}
                        />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          component="span"
                          sx={{

                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        >

                        </Typography>
                      </Box>
                    </Grid> */}
                    {/* ==============|SOCIAL LOGIN|============== */}
                    {/* <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="facebook"
                        startIcon={<FacebookOutlinedIcon />}
                        sx={{ mt: 1 }}
                      >
                        <LoginSocialFacebook
                          ref={facebookRef}
                          appId={"940922980912154"}
                          onLoginStart={onLoginStart}
                          onLogoutSuccess={onLogoutSuccess}
                          onResolve={({ provider, data }) => {
                            setProvider(provider);
                            setProfile(data);
                            console.log(data, "data");
                            console.log(provider, "provider");
                          }}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          Facebook
                        </LoginSocialFacebook>
                      </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <LoginSocialGoogle
                            ref={googleRef}
                            client_id = {process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
                            onLogoutSuccess={onLogoutSuccess}
                            onResolve={({ provider, data }) => {
                              googlelogin(data);
                              setProfile(data);

                            }}
                            onReject={(err) => {
                              console.error('Login rejected:', err);
                            }}
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              color="googlelogin" // Changed to 'primary' because 'twitter' is not a valid color in MUI
                              startIcon={isLoading ? <CircularProgress sx={{ color:'white' }} size={20} /> : <GoogleIcon />}
                              sx={{ mt: 1 }}
                              onClick={() => googleRef.current?.click()}
                            >
                              {/* {isLoading ? "Sign In with Google" : "Sign In with Google"} */}
                              Sign In with Google
                            </Button>
                          </LoginSocialGoogle>
                        </Grid>
                      </Grid>


                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>
      <>
        {
          openToast && <Toast open={openToast} message={toastMessage} type={toastType} onClose={closeToast} />
        }


      </>
    </>
  );
};
