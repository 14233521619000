import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography, Container, Link } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { makeApiCall } from '../../common/makeApiCall';
export default function Aboutuser() {
    const navigate = useNavigate(); 
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [initialValues, setInitialValues] = useState({
        // about:  about ? about : '',
        about:  '',
    });
    // console.log('initialValues', initialValues.about);
    const validationSchema = yup.object().shape({
        about: yup.string().matches().required('About is required'),
    });
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        // console.log("values", values);
        try {
            const response = await makeApiCall('post', '/user/createabout', {
                about: values.about,
            });
            resetForm();
            navigate("/")
        } catch (error) {
            console.error('Error adding post:', error);
        } finally {
            setSubmitting(false);
        }
    };
  return (
    <>
    
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: 'center', 
          minHeight: '70vh',
          padding: 6,
        }}
      >
        <Typography component="h1" variant="h5" >About</Typography>
        {/* <Typography component="h1" variant="h5"> */}
          {/* {header} */}
        {/* </Typography> */}
        <Box  noValidate
        sx={{
          mt: 1,
          width: isMobile ? '100%' : 900,
          maxWidth: '100%', 
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: 'center',  
        }}
       
    >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field fullWidth  id="fullWidth"
                    as={TextField}
                      name="about"
                      label="Write about yourself"
                    //   as={TextField}
                      helperText={<ErrorMessage name="about" />}
                    //   multiline
                    //   rows={isMobile ? 5 : 2} // Adjust the number of rows based on screen size
                    //   rowsMax={20} // Adjust the maximum number of rows as needed
                      margin="normal"
                      inputProps={{ maxLength: 490 }} // Limit the character count to 490
                      error={formik.touched.about && formik.errors.about}
                    sx={{ resize: "none", width: '100%' }} // Disable resizing
                    />
                  </Grid>

                  <Grid item xs={12}  sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: 'center', 
        }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 1,
                        width: isMobile ? '100%' : 200,
                        maxWidth: '100%',
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
}
