import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

export default function Copyrights() {
    const year = new Date().getFullYear();
    return (

        <>
            <Box sx={{ display: "flex", flexWrap: "wrap", padding: 1, gap: 1 }}>
                <Typography variant="caption">
                    <Link target="_blank" to="https://docs.google.com/document/d/1f3PbouLRzJ94zVvlmjh7r-SOAC4rF-dc2SdyWsx4rYQ/edit?usp=sharing">Privacy</Link>
                </Typography>
                <Typography variant="caption">
                    <Link target="_blank" to="https://docs.google.com/document/d/1Bwn-rR0YSRtouA9LLIDpdgyU-X27_9oaG5suWmLGoB8/edit?usp=sharing">Terms & Conditions</Link>
                </Typography>
                <Typography variant="caption">
                    <Link to="/community">Community Guidelines</Link>
                </Typography>
                <Typography variant="caption" >
                    <Link target="_blank" to="https://forms.gle/V1yczgn3kZ2swxoY9">Feedback</Link>
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', padding: 1 }}>
                <Typography variant="overline">
                    © {year} All rights reserved.
                </Typography>
            </Box>
        </>
    )
}
