import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Routes, Route } from "react-router-dom";
import { Home } from "../../pages/home/Home";
import { Login } from "../../pages/login/Login";
import { Signup } from "../../pages/signup/Signup";
import { Userprofile } from "../../pages/userprofile/Userprofile";
import { Viewpost } from "../../pages/viewpost/Viewpost";
import { Pagenotfound } from "../../pages/pagenotfound/Pagenotfound";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import { TextField, InputAdornment, Link } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate, NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MoreIcon from "@mui/icons-material/MoreVert";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Autocomplete from "@mui/material/Autocomplete";
import Adminlist from "../../pages/admin/adminlist/Adminlist";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InfoIcon from "@mui/icons-material/Info";
import About from "../../pages/about/About";
import Privacy from "../../pages/about/Privacy";
import Termsandcondition from "../../pages/about/Termsandcondition";
import CommunityGuidelines from "../../pages/about/community/CommunityGuidelines";
import Updates from "../../pages/about/Updates";
import Feedback from "../../pages/about/Feedback";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import Contact from "../../pages/about/Contact";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Addpostfield from "../addpostfield/Addpostfield";
import Copyrights from "../copyrights/Copyrights";
import Aboutuser from "../aboutuser/Aboutuser";
import Userpost from "../../pages/userprofile/Userpost";
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
const drawerWidth = 240;
const navItems = ["Log In",];
const settings = ["Profile", "Logout"];
// const icons = {
//   Home: HomeIcon,
//   Trending: TrendingUpIcon,
// };
const icons = {
  "About us": InfoIcon,
  "Contact us": ContactPageIcon,
  Trending: TrendingUpIcon,
  Admin: AdminPanelSettingsIcon, // Add Admin icon here
};


export const Navbar = (props) => {
  let token = localStorage.getItem("token");
  let userName = localStorage.getItem("userName");
  let userType = localStorage.getItem("userType");
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const listDrawerToggle = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileOpen(open);
  };
  //Profile start
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    // console.log("Logout");
    handleCloseUserMenu();
    localStorage.clear();
    if (userType === "AD") {
      navigate("/login");
    } else {
      // console.log("navigate logout");
      // alert("You have successfully logged out")
      navigate("/", {
        state: {
          open: true,
          message: "You have successfully logged out",
          type: 'success'
        }
      }
      );

    };
  };
  const handleProfile = () => {
    handleCloseUserMenu();
    navigate("/userprofile");
  };
  const handlePost = () => {
    handleCloseUserMenu();
    navigate("/userpost");
  };

  // Login and SignUp menu icon start ---->
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (text) => {
    // console.log("text", text);
    if (text === "About us") {
      navigate("/about");
    } else if (text === "Contact us") {
      navigate("/contact");
    } else if (text === "Admin") {
      navigate("/adminlist");
      // navigate("/admindashboard");
    } else {
      navigate("/");
    }
  };
  const year = new Date().getFullYear();
  const drawer = (
    <>
      {/* <Toolbar /> */}
      <Box role="presentation" onClick={listDrawerToggle(false)} >
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            textAlign: "center", // Align text to the center
            padding: "7px",
            // backgroundColor: "red",
          }}
        >
          {/* <BrandLogo src="openletter.svg" /> */}
          <BrandLogo src={`${process.env.PUBLIC_URL}/openletter.svg`} />
          <Link href="/" underline="none" sx={{ color: "inherit" }}>
            Open Letter
          </Link>
        </Typography>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '90vh' }}> {/* Ensures full viewport height */}
          <List sx={{ flexGrow: 1 }}> {/* Content area for list items */}
            {(userType === "AD" ? ["Admin"] : ["About us", "Contact us",]).map((text, index) => (
              <ListItem key={text} disablePadding onClick={() => handleOpen(text)}>
                <ListItemButton>
                  <ListItemIcon sx={{ color: 'primary.main' }}>
                    {React.createElement(icons[text], {})}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider />
          <Grid container sx={{ justifyContent: 'flex-end' }}>
            <Grid item xs={12} >
              <Copyrights />

            </Grid>
          </Grid>
        </Box>
      </Box>
    </>

  );
  const handleClearSearch = () => {
    setSearchTerm(''); // Clear the search term state
    // Optionally, navigate to "/" if needed (replace with your logic)
    // navigate("/");
  };
  const handleClear = () => {
    setSearchTerm(''); // Set the state to an empty string to clear the value
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          borderBottom: "1px solid #e0e0e0",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ backgroundColor: "#f5fbff", color: "#333" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            sx={{ alignItems: "center" }}
            // sx={{
            //   justifyContent : { xs : 'center',  sm : 'center', md : 'flex-start'}
            // }}
            spacing={2}
          >
            <Grid item xs={12} sx={{ display: 'flex', alignItems: "center", }}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  placeholder="Search title or msg..."
                  size="small"
                  sx={{
                    width: { xs: 200, md: 350 },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      height: "37px",
                      marginRight: "5px"
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ cursor: "pointer" }} disabled={searchTerm.length < 1} onClick={handleClear}>
                          <CloseIcon size="small" /> {/* Assuming you have a ClearIcon component */}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={searchTerm}
                  onChange={(event) => {
                    const value = event.target.value;
                    setSearchTerm(value);
                    navigate("/");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Addpostfield />
              </Grid>
            </Grid>

          </Grid>
          {token ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar
                      sx={{ width: 35, height: 35 }}
                      src={`${process.env.PUBLIC_URL}/profilelogo.svg`}
                    ></Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  sx={{
                    ":hover": {
                      backgroundColor: 'transparent', // or any other hover effect you want to remove
                      cursor: 'default',
                      boxShadow: 'none', // Removes any shadow on hover
                    },
                    justifyContent: 'center',
                  }}
                >
                  {/* <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon> */}
                  {userName}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleProfile}>
                  <ListItemIcon>
                    <AccountCircleIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handlePost}>
                  <ListItemIcon>
                    <LocalPostOfficeIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  My Posts
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>


            // <Box sx={{ flexGrow: 0 }}>
            //   <Tooltip title="Open settings">
            //     <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            //       <Avatar src="../../assets/icons/profile" />
            //     </IconButton>
            //   </Tooltip>
            //   <Menu
            //     sx={{ mt: "45px" }}
            //     id="menu-appbar"
            //     anchorEl={anchorElUser}
            //     anchorOrigin={{
            //       vertical: "top",
            //       horizontal: "right",
            //     }}
            //     keepMounted
            //     transformOrigin={{ vertical: "top", horizontal: "right" }}
            //     open={Boolean(anchorElUser)}
            //     onClose={handleCloseUserMenu}
            //   >
            //     {settings.map(
            //       (setting) =>
            //         (userType !== "AD" ||
            //           (userType === "AD" && setting !== "Profile")) && (
            //           <MenuItem
            //             key={setting}
            //             onClick={
            //               setting === "Logout" ? handleLogout : handleProfile
            //             }
            //           >
            //             <Typography textAlign="center">{setting}</Typography>
            //           </MenuItem>
            //         )
            //     )}
            //   </Menu>
            // </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {navItems.map((item, index) => (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    // Redirect to login or signup pages using `navigate`
                    if (item === "Log In") {
                      navigate("/login");
                    }
                    // else if (item === "Sign Up") {
                    //   // navigate("/signup");
                    // }
                  }}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                    marginRight: index !== navItems.length - 1 ? 1 : 0,
                    height: 32,
                    width: 80,
                  }}
                  key={item}
                >
                  {item}
                </Button>
              ))}

              <IconButton
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                }}
                size="large"
                aria-label="display more actions"
                edge="end"
                color="inherit"
                onClick={handleClick}
              >
                <MoreIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {navItems.map((item, index) => (
                  <MenuItem
                    key={item}
                    onClick={() => {
                      // Redirect to login or signup pages using `navigate`
                      if (item === "Log In") {
                        navigate("/login");
                      }
                      // else if (item === "Sign Up") {
                      //   navigate("/signup");
                      // }

                      handleClose(); // Close the menu after clicking on an item
                    }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ backgroundColor: "red", width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer

          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          marginTop: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home searchTerm={searchTerm} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/aboutuser" element={<Aboutuser />} />
          {userType === 'AD' && <Route path="/adminlist" element={<Adminlist />} />}
          {/* {userType === 'AD' && <Route path="/admindashboard" element={<Allpostlist />} />} */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          {/* <Route path="/addpost" element={<Addpost />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/userprofile" element={<Userprofile />} />
          <Route path="/userpost" element={<Userpost />} />
          <Route path="/viewpost/:id" element={<Viewpost />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Termsandcondition />} />
          <Route path="/community" element={<CommunityGuidelines />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </Box>
    </Box>
  );
};


const BrandLogo = styled("img")({
  width: "40px", // Adjust width as needed
  height: "40px", // Adjust height as needed
});
