import React from 'react'
import { Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const Pagenotfound = () => {
    return (
        <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h1" align="center">
                        404
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        The page you requested was not found. @Open Letter
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" component={Link} to="/" sx={{ width: '100%' }}>
                        Go Back Home
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}
