import React, { useState, useRef, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
//==================| Date Import |=====================//
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import Delta from "quill-delta";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Typography,
  DialogTitle,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { makeApiCall } from "../../common/makeApiCall";
import { Categorylist } from "../../data/categorylist/Categorylist";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../common/toastMessage";
import { Tamilnadudistrict } from "../../data/tamilnadudistrict/Tamilnadudistrict";
import DOMPurify from 'dompurify';

// ====================|REACTQUILL TOOLBAR OPTIONS |======================= //
// const youtubeEmbedHandler = (url) => {
//   const videoId = url.split('v=')[1].substring(0, 11); // Extract video ID from URL
//   return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
// };
const toolbarOptions = [
  ["bold", "italic"],
  // [{ 'header': 1 }, { 'header': 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // h1 to h6 tag
  // [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
  // [{ 'font': [] }],
  // [{ 'direction': 'rtl' }], // text direction
  [{ align: [] }],
  // ['link', 'image', { video: youtubeEmbedHandler }],
  // ['clean'] // remove formatting button
];

// ====================|FORMIK VALIDATION |======================= //
const stripHtmlTags = (html) => {
  const cleanText = DOMPurify.sanitize(html, { ALLOWED_TAGS: [] });
  return cleanText;
};
const validationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(10, "Title must be at least 10 characters")
    .max(50, "Title allows only 50 characters"),
  // .matches(/^[a-zA-Z0-9 ]*$/, "Only text and numbers are allowed"),
  organization: Yup.string()
    .required("Organization is required")
    .min(3, "Organization must be at least 3 characters")
    .max(50, "Organization allows only 50 characters"),
  // .matches(/^[a-zA-Z0-9 ]*$/, "Only text and numbers are allowed"),
  category: Yup.string().required("Category is required"),
  district: Yup.string().required("District is required"),
  city: Yup.string()
    .required("City is required")
    // .min(3, "city must be at least 3 characters")
    .max(50, "City allows only 50 characters"),
  // .matches(/^[a-zA-Z0-9 ]*$/, "Only text and numbers are allowed"),
  actionDate: Yup.string().required("Expected action date is required"),
  message: Yup.string()
    .test('len', 'Message is too short', (value) => {
      const plainText = stripHtmlTags(value || '').trim();
      return plainText.length > 20; // Adjust the length as per your requirements
    })
    .required('Message is required'),
});

// ============================|EXPORT FUNCTION START|===================================//
export default function Addpostmodal({
  open,
  onClose,
  updatePost,
  fetchPosts,
  fetchAdminPosts,
}) {
  let userType = localStorage.getItem("userType");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [content, setContent] = useState("");

  const closeToast = () => {
    setOpenToast(false);
  };
  // Toast End
  const [formErrors, setFormErrors] = useState({});
  const quillRef = React.createRef();
  const handleOpen = () => onClose(true);
  const handleClose = () => {
    setFormErrors({});
    // setOpen(false);
    onClose(false);
  };
  // ============================| REACT QUILLJS TEXT EDITOR FUNCTION START|===================================//

  // const stripHtmlTags = (html) => {
  //   const div = document.createElement("div");
  //   div.innerHTML = html;
  //   return div.textContent || div.innerText || "";
  // };

  const handleContentChange = (content, delta, source, onChange) => {
    const plainText = stripHtmlTags(content).trim();
    if (plainText === "<p><br><em></p>" || plainText === "") {
      onChange(""); // Set content to empty string to clear
    } else {
      onChange(content); // Update content normally otherwise
    }
  };
  // ====================|REACTQUILL DON'T COPY PASTE ALLOW FUNCTION |======================= //
  const pasteHandler = (event) => {
    event.preventDefault(); // Prevent default pasting behavior
    const clipboardText = (event.clipboardData || window.clipboardData).getData(
      "text/plain"
    );
    setContent(clipboardText); // Set content with plain text
  };

  // ====================|ADD POST API CALL |======================= //
  const formik = useFormik({
    initialValues: {
      title: updatePost?.title ? updatePost?.title : "",
      organization: updatePost?.organization ? updatePost?.organization : "",
      category: updatePost?.category ? updatePost?.category : "",
      district: updatePost?.district ? updatePost?.district : "",
      city: updatePost?.city ? updatePost?.city : "",
      actionDate: updatePost?.actionDate
        ? dayjs(updatePost?.actionDate, "DD-MM-YYYY")
        : null, // Convert to Day.js object
      message: updatePost?.message ? updatePost?.message : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      if (updatePost?.id) {
        try {
          setIsLoading(true);
          const formattedDate = dayjs(values.actionDate).format("YYYY-MM-DD");
          let endpoint;
          if (userType === "AD") {
            endpoint = "/post/admin/update";
            // console.log("adminupdate");
          } else {
            endpoint = "/post/update";
            // console.log("update");
          }
          const response = await makeApiCall(
            "post",
            endpoint,
            {
              id: updatePost?.id,
              title: values.title,
              message: values.message,
              category: values.category,
              district: values.district,
              city: values.city,
              organization: values.organization,
              actionDate: formattedDate,
            });
          // setOpenToast(true);
          // setToastMessage(response?.data?.message ? response?.data?.message : "");
          // setToastType("success");
          resetForm();
          setIsLoading(false);
        } catch (error) {
        } finally {
          setSubmitting(false);
          if (userType === "AD") {
            fetchAdminPosts();
            handleClose(); // Close the dialog
          } else {
            fetchPosts(); // Refresh the posts list after update UserprofileScreen
            handleClose(); // Close the dialog
          }
        }
      } else {
        try {
          setIsLoading(true);
          const formattedDate = dayjs(values.actionDate).format("YYYY-MM-DD");
          const response = await makeApiCall("post", "/post/create", {
            title: values.title,
            message: values.message,
            category: values.category,
            district: values.district,
            city: values.city,
            organization: values.organization,
            actionDate: formattedDate,
          });
          // console.log("resCreatePost", response);
          if (response.status === 200) {
            resetForm();
            setIsLoading(false);

            if (location.pathname === "/") {
              // If already on the home screen, force reload
              navigate(0);
            } else {
              // Navigate to the home screen
              navigate("/");
            }
          } else if (response.status === 400) {
            // setOpenToast(true);
            // setToastMessage(response?.message);
            // setToastType('error');
          } else {
            throw new Error("Unexpected error");
          }
        } catch (error) {
          // setOpenToast(true);
          // setToastMessage('An error occurred. Please try again.');
          // setToastType('error');
        } finally {
          setSubmitting(false);
          handleClose();
        }
      }
    },
  });

  const quill = quillRef.current?.getQuill(); // Get Quill instance
  if (quill) {
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      const text = node.textContent || ""; // Extract text content

      // Option 1: Basic text extraction (removes most formatting)
      const plainText = text.replace(/<[^>]*>/g, ""); // Remove HTML tags

      // Option 2: More advanced text extraction (consider using a library)
      // const plainText = htmlToText(text, { /* Options for handling formatting */ });

      const ops = delta.ops.map((op) => ({ insert: plainText }));
      return new Delta(ops);
    });
  }

  // ======================| Input validation|===================//
  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue.startsWith(" ")) {
      inputValue = inputValue.trimStart();
    }
    // Allow only text, numbers, and spaces
    inputValue = inputValue.replace(/[^a-zA-Z0-9@&\- ]/g, "");
    formik.setFieldValue(event.target.name, inputValue);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle sx={{ textAlign: "center" }} id="form-dialog-title">
          ✍️ Write an Open Letter...
        </DialogTitle>
        <DialogContent>
          {/* =========================|FORM START|====================== */}
          {/* <DialogContentText>
                                To register, please fill out the form below.
                            </DialogContentText> */}

          <form onSubmit={formik.handleSubmit}>
            <TextField
              margin="dense"
              id="title"
              label="Title"
              type="text"
              fullWidth
              name="title"
              value={formik.values.title}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              margin="dense"
              id="organization"
              label="Organization"
              type="text"
              fullWidth
              name="organization"
              value={formik.values.organization}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.organization &&
                Boolean(formik.errors.organization)
              }
              helperText={
                formik.touched.organization && formik.errors.organization
              }
            />
            <TextField
              margin="dense"
              id="category"
              label="Category"
              select
              fullWidth
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
            >
              {Categorylist.map((option) => (
                <MenuItem
                  sx={{ display: "flex", flexwrap: "wrap" }}
                  key={option.id}
                  value={option.name}
                  disabled={option.disabled}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              id="district"
              label="District"
              select
              fullWidth
              name="district"
              value={formik.values.district}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.district && Boolean(formik.errors.district)}
              helperText={formik.touched.district && formik.errors.district}
            >
              {Tamilnadudistrict.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.name}
                  disabled={option.disabled}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              id="city"
              label="City"
              type="text"
              fullWidth
              name="city"
              value={formik.values.city}
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            {/* ============| DATE PICKER |=============== */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%", mt: 2, mb: 2 }}
                disablePast
                label="Expected Action Date *"
                format="DD/MM/YYYY"
                // value={formik.values.actionDate}
                // selected={(updatePost?.actionDate && new Date(updatePost?.actionDate)) || null}
                // onChange={(value) => formik.setFieldValue("actionDate", value)}
                value={formik.values.actionDate || null} // Ensure value is a valid Day.js object or null
                onChange={(value) =>
                  formik.setFieldValue(
                    "actionDate",
                    value ? dayjs(value) : null
                  )
                } // Convert value to Day.js object
                slotProps={{
                  textField: {
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.actionDate), // Convert to boolean
                    helperText: formik.errors.actionDate, // Provide empty string if no helper text
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="actionDate"
                    fullWidth
                    margin="dense"
                  />
                )}
              />
            </LocalizationProvider>
            {/* ================| MESSAGE REACTQUILL |============= */}
            <Box onPaste={pasteHandler}>
              <ReactQuill
                ref={quillRef}
                name="message"
                className="editor"
                theme="snow"
                flex={1}
                style={{ borderRadius: "8px" }}
                value={formik.values.message}
                onChange={(content, delta, source) => {
                  handleContentChange(content, delta, source, (trimmedValue) => {
                    formik.setFieldValue("message", trimmedValue);
                  });
                }}
                modules={{ toolbar: toolbarOptions }}
                placeholder="Write your message here..."
                onBlur={formik.handleBlur.message}
              />
              {formik.touched.message && formik.errors.message && (
                <Box sx={{ color: "red", mt: 1 }}>{formik.errors.message}</Box>
              )}
            </Box>

            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>

              {updatePost?.id ? (
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  startIcon={isLoading ? <CircularProgress size={20} /> : null}
                >
                  {isLoading ? "Updated" : "Update"}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  startIcon={isLoading ? <CircularProgress size={20} /> : null}
                >
                  {isLoading ? "Publish" : "Publish"}
                </Button>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <>
        {openToast && (
          <Toast
            open={openToast}
            message={toastMessage}
            type={toastType}
            onClose={closeToast}
          />
        )}
      </>
    </>
  );
}
