import React, { useState, useEffect } from "react";
import { Grid, MenuItem, FormControl, Box, TextField, Stack, Button, RadioGroup, Radio, DialogActions, DialogContent, DialogTitle, FormControlLabel, Dialog, IconButton } from '@mui/material';
import { makeApiCall } from "../../common/makeApiCall";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Reportlist } from "../../data/reportlist/Reportlist";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';


export default function Reportpost({ flagId, setOpenReport }) {
    const navigate = useNavigate();
    const [openDailogReport, setOpenDailogReport] = useState(true);
    const handleClickClose = () => {
        setOpenDailogReport(false);
        setOpenReport(false);
    };
    // =========================| REPORT POST API CALL |===================================>
    const [initialValues, setInitialValues] = useState({
        postId: '',
        complaintId: ''
    });
    const validationSchema = Yup.object({
        complaintId: Yup.string().required('Please select a report reason'),
    });
    const reportSubmit = async (values, { setSubmitting, resetForm }) => {
        const userToken = localStorage.getItem('token');
        if (!userToken) {
            navigate("/login")
        }
        try {
            const response = await makeApiCall('post', '/post/complaint', {
                postId: flagId,
                complaintId: values?.complaintId
            });
            console.log('reportsuccess', response.data);
            handleClickClose(); // Corrected function call
        } catch (error) {
            console.error('Error reporting post:', error);
        }
    };
    const hasToken = localStorage.getItem('token');
    return (
        <>
            <Dialog
                open={openDailogReport}
            >
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={reportSubmit}>
                    {(formik) => (
                        <Form>
                            <DialogTitle>Report Post</DialogTitle>
                            <DialogContent>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="complaintId"
                                        value={formik.values.complaintId}
                                        onChange={formik.handleChange} // Corrected handler
                                    >
                                        {Reportlist.map((report) => (
                                            <FormControlLabel
                                                key={report.complaintId} // Use the unique id as the key for better performance
                                                value={report.complaintId} // Set the value to the report ID for easy identification
                                                control={<Radio />}
                                                label={report.name}
                                            />
                                        ))}
                                    </RadioGroup>
                                    {formik.touched.complaintId && formik.errors.complaintId ? ( // Display error message
                                        <Box sx={{ color: "red" }}>{formik.errors.complaintId}</Box>
                                    ) : null}
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" color="error" onClick={handleClickClose}>Cancel</Button>
                                <Button variant="contained" color="error" type="submit">Report</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}
