    import { WidthFull } from "@mui/icons-material";

    export const customStyles = {
        title: {
            style: {
                fontColor: 'red',
                fontWeight: '900',
            },
        },
        rows: {
            style: {
                backgroundColor: 'white',
            },
        },
        headCells: {
            style: {
                backgroundColor: '#142d55',
                color: '#ffffff',
                // fontFamily: 'Public Sans',
                fontSize: '0.875rem',
                fontWeight: '500',
                padding: '8px',
                // WidthFull
                height:'50px'
            }
        },
        cells: {
            style: {
                // fontFamily: 'Public Sans',
                fontSize: '0.875rem',
                padding: '8px',
                height:'50px'
                // width: '220px',
            }
        }
    };