import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Button, Box, Grid, Typography, Container, Link } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { makeApiCall } from '../../common/makeApiCall';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Toast from '../../common/toastMessage';
import CircularProgress from '@mui/material/CircularProgress';

export const Createprofile = ({ header, openAboutField, fetchAbout, about }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    // Toast Start

    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();

    // Toast End

    const [initialValues, setInitialValues] = useState({
        about: about ? about : '',
    });
    const validationSchema = yup.object().shape({
        about: yup.string().matches(
            // /^[a-zA-Z0-9\-_\s]+$/, // Allow letters, numbers, hyphen, underscore, and whitespace
            // 'About can only contain letters, numbers, hyphens, underscores, and spaces'
        ).required('About is required'),
    });
    // const handleSubmit = (data) => {
    //     console.log("dataAbout", data);
    //     navigate("/", { state: { aboutData: data } }); // Pass aboutData to the login screen
    // };
    // const handleSubmit = (data) => {
    //     console.log('dataAbout', data);
    //     // Perform any necessary actions with the form data
    //     onClose(); // Call the onClose function to close the dialog
    // };
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        try {

            const response = await makeApiCall('post', '/user/createabout', {
                about: values.about,
            });

            // console.log("Handlesubmit:", response?.data?.message);
            setOpenToast(true);
            setToastMessage(response?.data?.message)
            setToastType('success')
            resetForm();
            openAboutField(false);
            fetchAbout()
            // navigate("/", { state: { aboutData: 'data' } }); // Pass aboutData to the login screen
            setIsLoading(false);
        } catch (error) {
            // console.error('Error adding post:', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        // <>
        //     <p>Createprofile</p>
        // </>
        <>
            {/* <Container component="main" maxWidth="xs" sx={{
                backgroundColor: "white", borderRadius: 3,
                border: '1px solid #e2e2e2',
                marginTop: 5
            }}> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // backgroundColor: 'red',
                    padding: 6,
                }}
            >
                <Typography component="h1" variant="h5">
                    {header}
                </Typography>
                <Box noValidate sx={{ mt: 1 }}>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {(formik) => (
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {/* <Field
                                                name="about"
                                                // label="Name"
                                                label="Write about yourself"
                                                as={TextField}
                                                helperText={<ErrorMessage name="about" />}
                                                fullWidth
                                                margin="normal"
                                                error={formik.touched.about && formik.errors.about}

                                            /> */}
                                        <Field
                                            name="about"
                                            label="Write about yourself"
                                            as={TextField}
                                            helperText={<ErrorMessage name="about" />}
                                            fullWidth
                                            multiline
                                            rows={10} // Adjust the number of rows as needed
                                            rowsMax={20} // Adjust the maximum number of rows as needed
                                            margin="normal"
                                            inputProps={{ maxLength: 490 }} // Limit the character count to 490
                                            // error={formik.touched.about && formik.errors.about}
                                            error={!!(formik.touched.about && formik.errors.about)} // Convert to boolean
                                            sx={{ resize: 'none' }} // Disable resizing
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 1 }}
                                            disabled={isLoading}
                                            startIcon={isLoading ? <CircularProgress size={20} /> : null} // Disable button while loading
                                        >
                                            Submit
                                            {/* {isLoading ? <CircularProgress size="small" color="primary" /> : 'Submit'} */}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box >
            {/* </Container > */}
            {
                openToast && <Toast open={openToast} message={toastMessage} type={toastType} />
            }
        </>
    );
};
